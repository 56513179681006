import { Edit, Mail, UserPlus, UserMinus, User, Trash2 } from 'react-feather';
import { MoreOutlined, DownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Menu, message, Modal, Row, Tooltip, Typography } from 'antd';
import STable from 'components/Standard/STable';
import UserPreview from 'components/UserPreview';
import { USER_STATUSES } from 'core/utils/constants';
import { get, isEmpty, sortBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions, operations } from 'redux/lists/usersListReducer';
import { usersResource } from 'redux/resources/users';
import Icon from 'components/Icon';
import { updateEmployeeModal } from 'redux/ui/employeeModal/reducer';
import SCard from 'components/Standard/SCard';
import { IconButton } from 'components/Buttons';
import SText from 'components/Standard/SText';
import { SRow } from 'components/Standard';
import { setEmployees } from 'redux/ui/checklistEditor/reducer';
import EmployeeModal from '../components/EmployeeModal';
import { AddEmployeeButtonCol, FilterCol } from './styled';
import TableSettings from './TableSettings';
import { deleteEmployeeRequest } from '../../../../core/api';
import SlicedTextWithTooltip from '../../../../components/SlicedTextWithTooltip';

const { Text } = Typography;

const Employees = ({
  users,
  usersByIds,
  unitsByIds,
  rolesByIds,
  loading,
  totalCount,
  page,
  filters,
  query,
  loadUsers,
  updateUserById,
  inviteUserById,
  updatePage,
  history,
  setQuery,
  updateEmployeeModal,
  levelsByIds,
  search,
  setSearch
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [contentModal, setContentModal] = useState(null);

  const { organization } = useSelector(state => state.organizationInfo);
  const { activeUsersCount, activeUsersCountLimit, licenseExpirationDate } = organization;
  const expirationDate = new Date(licenseExpirationDate);
  const currentDate = new Date();

  const isLicenseExpired = currentDate > expirationDate;
  const LimitActiveUsersExceeded = activeUsersCount > activeUsersCountLimit;

  const showTooltip = isLicenseExpired || LimitActiveUsersExceeded;

  const getTooltip = () => {
    if (showTooltip) {
      if (isLicenseExpired) {
        return t('organizationStructure.tabs.employees.isLimitExpired.isLicenseExpired.text');
      }
      if (LimitActiveUsersExceeded) {
        return t('organizationStructure.tabs.employees.isLimitExpired.usersCountLimit.text');
      }
    }
    return '';
  };

  const tooltip = getTooltip();

  useEffect(() => {
    const { search } = history.location;
    if (search) {
      setQuery(search);
    } else if (query) {
      loadUsers({
        page,
        filters,
        sort: 'last_name',
        include: 'unit,role.levels'
      });
      history.push({ search: query });
    }

    return () => setSearch();
  }, []);

  useEffect(() => {
    loadUsers({
      search,
      page,
      filters,
      sort: 'last_name',
      include: 'unit,role.levels'
    });
  }, [
    search,
    page.number,
    page.size,
    filters.rolesIds,
    filters.unitsIds,
    filters.active,
    filters.invitationStatus
  ]);

  const onCreateUser = () => {
    loadUsers({
      search,
      page,
      filters,
      sort: 'last_name',
      include: 'unit,role.levels'
    });
  };

  const tableConfig = {
    columns: [
      {
        title: t('organizationStructure.tabs.employees.table.columns.name'),
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        width: 300,
        render: (text, user) => {
          return <UserPreview userId={user.id} disabled margin="0px" showAvatar showEmail />;
        }
      },
      {
        title: (
          <SRow type="flex" alignItems="center">
            <Tooltip title={t('organizationStructure.tabs.employees.table.columns.unit')}>
              <SText>{t('organizationStructure.tabs.employees.table.columns.unit')}</SText>
            </Tooltip>
            <IconButton
              tooltip={{
                title: t('organizationStructure.tabs.employees.table.columns.unitsTooltip'),
                overlayStyle: { width: '393px' }
              }}
              button={{
                icon: <Icon icon={InfoCircleOutlined} />,
                size: 'icon'
              }}
            />
          </SRow>
        ),
        key: 'unit',
        dataIndex: 'name',
        width: 150,
        ellipsis: {
          showTitle: false
        },
        render: (text, user) => {
          const unitName = get(unitsByIds, `${user.unitId}.name`);
          return (
            <Tooltip title={unitName} placement="topLeft">
              <SText>{unitName}</SText>
            </Tooltip>
          );
        }
      },
      {
        title: (
          <SRow type="flex" alignItems="center">
            <Tooltip title={t('organizationStructure.tabs.employees.table.columns.managedUnits')}>
              <SText>{t('organizationStructure.tabs.employees.table.columns.managedUnits')}</SText>
            </Tooltip>
            <IconButton
              tooltip={{
                title: t('organizationStructure.tabs.employees.table.columns.managedUnitsTooltip'),
                overlayStyle: { width: '393px' }
              }}
              button={{
                icon: <Icon icon={InfoCircleOutlined} />,
                size: 'icon'
              }}
            />
          </SRow>
        ),
        key: 'managedUnitsIds',
        dataIndex: 'managedUnitsIds',
        width: 250,
        ellipsis: {
          showTitle: false
        },
        // render: (managedUnitsIds, user)
        render: managedUnitsIds => {
          const managedUnitsNames = managedUnitsIds.map(id => unitsByIds[id]?.name).join(', ');
          return (
            <Tooltip title={managedUnitsNames} placement="topLeft">
              <SText>{managedUnitsNames}</SText>
            </Tooltip>
          );
        }
      },
      {
        title: t('organizationStructure.tabs.employees.table.columns.role'),
        key: 'role',
        dataIndex: 'role',
        width: 150,
        render: (text, user) => {
          return <span>{get(rolesByIds, `${user.roleId}.name`)}</span>;
        }
      },
      {
        title: t('organizationStructure.tabs.employees.table.columns.status'),
        key: 'isActive',
        dataIndex: 'active',
        width: 100,
        render: active => {
          return (
            <Text style={{ color: active ? 'var(--green_6)' : 'var(--red_6)' }}>
              {active ? t(USER_STATUSES.ACTIVE.name) : t(USER_STATUSES.DISABLED.name)}
            </Text>
          );
        }
      },
      {
        title: t('organizationStructure.tabs.employees.table.columns.level'),
        key: 'level',
        dataIndex: 'level',
        width: 150,
        ellipsis: true,
        render: (text, user) => {
          const role = get(rolesByIds, user.roleId, {});
          const userLevel = get(levelsByIds, user.levelId, {});
          const roleLevels = role.levelsIds
            ? sortBy(
                role.levelsIds.map(id => levelsByIds[id]),
                'rank'
              )
            : [];

          const onSelectUserRole = ({ key: levelId }) => updateUserById({ id: user.id, levelId });

          const menu = (
            <Menu selectedKeys={userLevel.id} onClick={onSelectUserRole}>
              {roleLevels.map(level => (
                <Menu.Item key={level.id}>{level.name}</Menu.Item>
              ))}

              {!isEmpty(userLevel) && (
                <Menu.Item key={undefined}>
                  {t('organizationStructure.tabs.employees.accessLevels.buttons.resetLevel')}
                </Menu.Item>
              )}
            </Menu>
          );

          const dropdownText =
            userLevel.name ||
            t('organizationStructure.tabs.employees.accessLevels.buttons.setLevel');

          return isEmpty(roleLevels) ? (
            ''
          ) : (
            <Dropdown overlay={menu}>
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                {`${dropdownText} `}
                <DownOutlined />
              </a>
            </Dropdown>
          );
        }
      },
      {
        title: t('organizationStructure.tabs.employees.table.columns.telegram'),
        key: 'tgName',
        dataIndex: 'tgName',
        width: 150,
        render: (text, record) => {
          return <SlicedTextWithTooltip value={record?.tgName} length={15} template="..." />;
        }
      },
      {
        title: t('organizationStructure.tabs.employees.table.columns.access'),
        key: 'access',
        dataIndex: 'invitationSent',
        width: 240,
        ellipsis: true,
        render: (invitationSent, { active, lastActiveAt }) => {
          if (!active) {
            return t('organizationStructure.tabs.employees.accessLevels.noAccess');
          }

          if (lastActiveAt) {
            return (
              <SRow type="flex" alignItems="center">
                {t('organizationStructure.tabs.employees.accessLevels.loggedIn')}
                <IconButton
                  tooltip={{
                    title: `${t(
                      'organizationStructure.tabs.employees.lastAuthorization'
                    )}: ${moment(lastActiveAt).fromNow()}`,
                    overlayStyle: { width: 500 }
                  }}
                  button={{
                    icon: <Icon icon={InfoCircleOutlined} />,
                    size: 'icon'
                  }}
                />
              </SRow>
            );
          }

          if (invitationSent) {
            return t('organizationStructure.tabs.employees.accessLevels.invited');
          }

          return t('organizationStructure.tabs.employees.accessLevels.notInvited');
        }
      },
      {
        title: '',
        key: 'id',
        dataIndex: 'id',
        width: 40,
        fixed: 'right',
        render: (active, user) => {
          return (
            <Dropdown overlay={menu({ id: user.id })} trigger={['click']} placement="bottomLeft">
              <Button icon={<Icon icon={MoreOutlined} />} />
            </Dropdown>
          );
        }
      }
    ]
  };

  const editEmployee = ({ userId }) => {
    updateEmployeeModal({
      isEditMode: true,
      userId
    });
  };

  const addEmployee = async () => {
    if (!showTooltip) {
      updateEmployeeModal({ userId: null, isEditMode: false });
    }
  };

  const sendInvite = async ({ id }) => {
    const result = await inviteUserById({ id });

    if (result) {
      message.success(
        t('organizationStructure.tabs.employees.table.menu.messages.invitationSended')
      );
    }
  };

  const loadingStatus = useSelector(state => state.uiChecklistEditor.employees);

  const deleteEmployee = async ({ id }) => {
    if (id) {
      Modal.confirm({
        centered: true,
        title: t('components.checklistManager.checklistManagerHead.confirmDeleteEmployee.title'),
        content: t(
          'components.checklistManager.checklistManagerHead.confirmDeleteEmployee.description'
        ),
        okText: t('components.checklistManager.checklistManagerHead.confirmDeleteEmployee.ok'),
        cancelText: t(
          'components.checklistManager.checklistManagerHead.confirmDeleteEmployee.cancel'
        ),
        onOk: async () => {
          const deleteEmployeesObject = {};
          deleteEmployeesObject.action = 'users';
          deleteEmployeesObject.method = 'deleteUsers';
          deleteEmployeesObject.data = [id];
          const timeout = 10000; // 10 секунд
          let timerId;

          const requestPromise = new Promise((resolve, reject) => {
            timerId = setTimeout(() => {
              reject(new Error('Gateway Timeout'));
            }, timeout);
          });
          try {
            // const response = await fetch(deleteEmployeeRequest, {
            //   method: 'POST',
            //   headers: {
            //     'Content-Type': 'application/json'
            //   },
            //   body: JSON.stringify(deleteEmployeesObject)
            // });
            const response = await Promise.race([
              requestPromise,
              fetch(deleteEmployeeRequest, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(deleteEmployeesObject)
              })
            ]);
            clearTimeout(timerId);
            if (response.ok) {
              loadUsers({
                search,
                page,
                filters,
                sort: 'last_name',
                include: 'unit,role.levels'
              });
              sessionStorage.setItem([id], true);
              dispatch(setEmployees({ [id]: true }));
            } else {
              throw new Error(response.statusText);
            }
          } catch (error) {
            if (error.message === 'Gateway Timeout') {
              setVisible(!visible);
              setContentModal(
                t(
                  'components.checklistManager.checklistManagerHead.confirmDeleteEmployee.errors.errorTimeout'
                )
              );
              sessionStorage.setItem([id], true);
              dispatch(setEmployees({ [id]: true }));
            } else {
              message.warning(t('errorPages.internalServerError.title'));
              console.error('Fetch error:', error);
            }
          }
        }
      });
    }
  };

  const handleMenuClick = (eventType, id) => {
    switch (eventType.key) {
      case 'changeEmployeeStatus':
        return updateUserById({
          id,
          active: !get(usersByIds, [id, 'active'])
        });

      case 'editEmployee':
        return editEmployee({ userId: id });

      case 'sendInvite':
        return sendInvite({ id });

      case 'employeeProfile':
        return window.open(`/user/${id}/user-settings`);

      case 'deleteEmployee':
        return deleteEmployee({ id });

      default:
        return console.log('');
    }
  };

  const menu = ({ id }) => {
    const editableUserStatus = get(usersByIds, [id, 'active']);

    return (
      <Menu onClick={e => handleMenuClick(e, id)}>
        <Menu.Item key="changeEmployeeStatus">
          {editableUserStatus ? <Icon icon={UserMinus} /> : <Icon icon={UserPlus} />}
          <span>
            {editableUserStatus
              ? t('organizationStructure.tabs.employees.table.menu.items.deactivate')
              : t('organizationStructure.tabs.employees.table.menu.items.activate')}
          </span>
        </Menu.Item>
        <Menu.Item key="sendInvite">
          <Icon icon={Mail} />
          <span>{t('organizationStructure.tabs.employees.table.menu.items.sendInvite')}</span>
        </Menu.Item>
        <Menu.Item key="editEmployee">
          <Icon icon={Edit} />
          <span>{t('organizationStructure.tabs.employees.table.menu.items.editEmployee')}</span>
        </Menu.Item>
        <Menu.Item key="employeeProfile">
          <Icon icon={User} />
          <span>{t('organizationStructure.tabs.employees.table.menu.items.employeeProfile')}</span>
        </Menu.Item>
        <Menu.Item
          disabled={!!sessionStorage.getItem([id]) || !!loadingStatus[id]}
          key="deleteEmployee"
          style={{ color: 'var(--red_6)' }}
        >
          <Icon icon={Trash2} />
          <span>
            {!!sessionStorage.getItem([id]) || !!loadingStatus[id]
              ? t('organizationStructure.tabs.employees.table.menu.items.deleteEmployeeStatus')
              : t('organizationStructure.tabs.employees.table.menu.items.deleteEmployee')}
          </span>
        </Menu.Item>
        <Modal
          title={t(
            'components.checklistManager.checklistManagerHead.confirmDeleteEmployee.errors.errorTimeoutTitle'
          )}
          visible={visible}
          cancelButtonProps={{ style: { display: 'none' } }} // hide cancel button
          onOk={() => setVisible(false)}
        >
          {contentModal}
        </Modal>
      </Menu>
    );
  };

  const handleTableChange = pagination => {
    const { pageSize, current } = pagination;
    updatePage({ size: pageSize.toString(), number: current.toString() });
  };

  return (
    <>
      <Helmet>
        <title>{t('pagesMeta.organizationStructurePage.tabs.employees.title')}</title>
      </Helmet>
      <SCard bordered shadowed>
        <Row
          type="flex"
          align="start"
          justify="space-between"
          gutter={[8, 8]}
          style={{ margin: '-4px -4px 4px' }}
        >
          <FilterCol span={20}>
            <Row type="flex" align="middle" gutter={[8, 8]} style={{ margin: '-4px' }}>
              <Col flex="auto">
                <TableSettings totalCount={totalCount} />
              </Col>
            </Row>
          </FilterCol>

          <AddEmployeeButtonCol span={4}>
            <Row type="flex" align="start" gutter={[8, 8]} style={{ margin: '-4px' }}>
              <Col span={24}>
                <Tooltip title={tooltip}>
                  <Button
                    disabled={showTooltip}
                    icon={<Icon icon={UserPlus} />}
                    type="primary"
                    onClick={addEmployee}
                  >
                    {t('organizationStructure.tabs.employees.table.buttons.addEmployee')}
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </AddEmployeeButtonCol>
        </Row>
        <STable
          dataSource={users}
          rowKey="id"
          size="small"
          loading={loading}
          columns={tableConfig.columns}
          border
          pagination={{
            pageSize: parseInt(page.size),
            current: parseInt(page.number),
            total: totalCount,
            showSizeChanger: true,
            pageSizeOptions: ['10', '25', '50']
          }}
          onChange={handleTableChange}
          scroll={{ y: 'calc(100vh - 400px)' }}
        />
        <EmployeeModal onCreateUser={onCreateUser} />
      </SCard>
    </>
  );
};

const mapStateToProps = state => {
  const { loading: listLoading, ids, totalCount, page, filters, query, search } = state.usersList;

  const users = ids.map(id => state.usersResource.byIds[id]).filter(user => !!user);
  const loading =
    listLoading ||
    state.usersResource.loading ||
    state.usersResource.updateByIdStarted ||
    state.usersResource.deleteByIdStarted;

  return {
    loading,

    users,
    unitsByIds: state.unitsResource.byIds,
    rolesByIds: state.rolesResource.byIds,
    levelsByIds: state.levelsResource.byIds,
    usersByIds: state.usersResource.byIds,

    totalCount,
    page,
    filters,
    query,
    search
  };
};

const mapDispatchToProps = {
  loadUsers: operations.load,
  createUser: usersResource.operations.create,
  deleteUserById: usersResource.operations.deleteById,
  updateUserById: usersResource.operations.updateById,
  inviteUserById: usersResource.operations.inviteById,
  updatePage: actions.updatePage,
  setQuery: actions.setCurrentQuery,
  setSearch: actions.setSearch,
  updateEmployeeModal
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Employees));
