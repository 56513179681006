import React from 'react';
import { WIDGETS_DESCRIPTIONS, WIDGET_VIEW_MODES } from 'core/utils/constants';
import { Trans } from 'react-i18next';

export default {
  table_reviews_count_by_operators: {
    type: 'table_reviews_count_by_operators',
    description: (
      <Trans i18nKey={WIDGETS_DESCRIPTIONS.TABLE_REVIEWS_COUNT_BY_OPERATORS.description} />
    ),
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.TABLE_REVIEWS_COUNT_BY_OPERATORS.title} />,
    dateMode: 'default',
    availableSizes: ['extra_large'],
    defaultViewMode: 'table',
    widget_names: {
      table: [
        'checklist_definition_average_score_by_operators',
        'checklist_definition_average_score_by_operators_history',
        'reviews_count_by_operators_history',
        'reviews_count_by_operators',
        'checklist_definition_average_score',
        'checklist_definition_average_score_history'
      ],
      chart: [
        'checklist_definition_average_score_history',
        'checklist_definition_average_score_by_operators',
        'checklist_definition_average_score_by_operators_history'
      ]
    },
    availableFilters: [
      {
        type: 'isWeekendView'
      },
      {
        type: 'dates'
      },
      {
        type: 'historyGroupingPeriod'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'clientInteractionDirection'
      },
      {
        type: 'singleChecklistDefinitionId'
      }
    ]
  },
  table_checklist_items_by_operators: {
    type: 'table_checklist_items_by_operators',
    description: (
      <Trans i18nKey={WIDGETS_DESCRIPTIONS.TABLE_CHECKLIST_ITEMS_BY_OPERATORS.description} />
    ),
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.TABLE_CHECKLIST_ITEMS_BY_OPERATORS.title} />,
    dateMode: 'default',
    availableSizes: ['extra_large'],
    defaultViewMode: 'questions',
    widget_names: {
      questions: [
        'average_score_by_questions',
        'checklist_definition_average_score',
        'checklist_question_average_scores_by_operators',
        'checklist_definition_average_score_by_operators',
        'reviews_count_by_operators'
      ],
      groups: [
        'average_score_by_questions',
        'checklist_definition_average_score',
        'checklist_definition_average_score_by_operators',
        'checklist_question_group_average_scores_by_operators',
        'reviews_count_by_operators'
      ]
    },
    availableFilters: [
      {
        type: 'dates'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'clientInteractionDirection'
      },
      {
        type: 'singleChecklistDefinitionId'
      },
      {
        type: 'multipleChecklistDefinitionsIdsForFilters'
      }
    ]
  },
  table_checklist_items_by_operators_multi: {
    type: 'table_checklist_items_by_operators_multi',
    description: (
      <Trans i18nKey={WIDGETS_DESCRIPTIONS.TABLE_CHECKLIST_ITEMS_BY_OPERATORS_MULTI.description} />
    ),
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.TABLE_CHECKLIST_ITEMS_BY_OPERATORS_MULTI.title} />,
    dateMode: 'default',
    availableSizes: ['extra_large'],
    defaultViewMode: 'questions',
    defaultViewType: WIDGET_VIEW_MODES.PERCENTAGE.value,
    defaultViewResult: WIDGET_VIEW_MODES.SUM.value,
    widget_names: {
      questions: [
        'average_score_by_questions',
        'checklist_definition_average_score',
        'checklist_question_average_scores_by_operators',
        'checklist_definition_average_score_by_operators',
        'reviews_count_by_operators'
      ],
      groups: [
        'average_score_by_questions',
        'checklist_definition_average_score',
        'checklist_definition_average_score_by_operators',
        'checklist_question_group_average_scores_by_operators',
        'reviews_count_by_operators',
        'average_score_by_groups'
      ]
    },
    availableFilters: [
      {
        type: 'dates'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'clientInteractionDirection'
      },
      {
        type: 'singleChecklistDefinitionId'
      },
      {
        type: 'multipleChecklistDefinitionsIdsForFilters'
      }
    ]
  },
  table_checklist_items_by_operators_multi: {
    type: 'table_checklist_items_by_operators_multi',
    description: (
      <Trans i18nKey={WIDGETS_DESCRIPTIONS.TABLE_CHECKLIST_ITEMS_BY_OPERATORS_MULTI.description} />
    ),
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.TABLE_CHECKLIST_ITEMS_BY_OPERATORS_MULTI.title} />,
    dateMode: 'default',
    availableSizes: ['extra_large'],
    defaultViewMode: 'questions',
    defaultViewType: WIDGET_VIEW_MODES.PERCENTAGE.value,
    defaultViewResult: WIDGET_VIEW_MODES.SUM.value,
    widget_names: {
      questions: [
        'average_score_by_questions',
        'checklist_definition_average_score',
        'checklist_question_average_scores_by_operators',
        'checklist_definition_average_score_by_operators',
        'reviews_count_by_operators'
      ],
      groups: [
        'average_score_by_questions',
        'checklist_definition_average_score',
        'checklist_definition_average_score_by_operators',
        'checklist_question_group_average_scores_by_operators',
        'reviews_count_by_operators',
        'average_score_by_groups'
      ]
    },
    availableFilters: [
      {
        type: 'dates'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'clientInteractionDirection'
      },
      {
        type: 'singleChecklistDefinitionId'
      },
      {
        type: 'multipleChecklistDefinitionsIdsForFilters'
      }
    ]
  },
  table_checklist_items_history: {
    type: 'table_checklist_items_history',
    description: <Trans i18nKey={WIDGETS_DESCRIPTIONS.TABLE_CHECKLIST_ITEMS_HISTORY.description} />,
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.TABLE_CHECKLIST_ITEMS_HISTORY.title} />,
    dateMode: 'default',
    availableSizes: ['extra_large'],
    defaultViewMode: 'questions',
    widget_names: {
      questions: [
        'reviews_count_history',
        'average_score_by_questions',
        'checklist_definition_average_score',
        'checklist_definition_average_score_history',
        'average_score_by_questions_history'
      ],
      groups: [
        'reviews_count_history',
        'checklist_definition_average_score',
        'checklist_definition_average_score_history',
        'average_score_by_question_groups_history'
      ]
    },
    availableFilters: [
      {
        type: 'isWeekendView'
      },
      {
        type: 'dates'
      },
      {
        type: 'historyGroupingPeriod'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'clientInteractionDirection'
      },
      {
        type: 'singleChecklistDefinitionId'
      }
    ]
  },
  flags_and_comments: {
    type: 'flags_and_comments',
    description: <Trans i18nKey={WIDGETS_DESCRIPTIONS.FLAGS_AND_COMMENTS.description} />,
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.FLAGS_AND_COMMENTS.title} />,
    dateMode: 'default',
    availableSizes: ['extra_large'],
    defaultViewMode: 'operators',
    widget_names: {
      operators: ['rating_flags_count', 'rating_flags_count_by_operators'],
      units: ['rating_flags_count', 'rating_flags_count_by_units']
    },
    availableFilters: [
      {
        type: 'dates'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'multipleChecklistDefinitionsIds'
      }
    ]
  },
  table_checklists_scores_by_operators: {
    type: 'table_checklists_scores_by_operators',
    description: (
      <Trans i18nKey={WIDGETS_DESCRIPTIONS.TABLE_CHECKLISTS_SCORES_BY_OPERATORS.description} />
    ),
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.TABLE_CHECKLISTS_SCORES_BY_OPERATORS.title} />,
    dateMode: 'default',
    availableSizes: ['extra_large'],
    defaultViewMode: 'operators',
    widget_names: {
      operators: [
        'checklist_definitions_average_scores_by_operators',
        'checklist_definition_average_score_by_operators',
        'reviews_count_by_operators',
        'checklist_definition_average_score',
        'average_score_by_checklist_definitions'
      ],
      units: [
        'checklist_definitions_average_scores_by_units',
        'checklist_definition_average_score_by_units',
        'reviews_count_by_units',
        'checklist_definition_average_score',
        'average_score_by_checklist_definitions'
      ]
    },
    availableFilters: [
      {
        type: 'dates'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'clientInteractionDirection'
      },
      {
        type: 'requiredMultipleChecklistDefinitionsIds'
      }
    ]
  },
  table_reviews_count_by_reviewers: {
    type: 'table_reviews_count_by_reviewers',
    description: (
      <Trans i18nKey={WIDGETS_DESCRIPTIONS.TABLE_REVIEWS_COUNT_BY_REVIEWERS.description} />
    ),
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.TABLE_REVIEWS_COUNT_BY_REVIEWERS.title} />,
    dateMode: 'review',
    availableSizes: ['extra_large'],
    defaultViewMode: 'reviewers',
    defaultViewType: 'table',
    widget_names: {
      table: {
        reviewers: [
          'reviews_count_by_reviewers',
          'reviews_count_by_reviewers_history',
          'reviewed_client_interactions_duration_by_reviewers',
          'reviewed_client_interactions_duration_by_reviewers_history'
        ],
        units: [
          'reviews_count_by_units',
          'reviews_count_by_units_history',
          'reviewed_client_interactions_duration_by_units',
          'reviewed_client_interactions_duration_by_units_history'
        ]
      },
      chart: {
        reviewers: [
          'reviews_count_by_reviewers',
          'reviews_count_by_reviewers_history',
          'reviewed_client_interactions_duration_by_reviewers',
          'reviewed_client_interactions_duration_by_reviewers_history'
        ],
        units: [
          'reviews_count_by_units',
          'reviews_count_by_units_history',
          'reviewed_client_interactions_duration_by_units',
          'reviewed_client_interactions_duration_by_units_history'
        ]
      }
    },
    availableFilters: [
      {
        type: 'reviewDates'
      },
      {
        type: 'historyGroupingPeriod'
      },
      {
        type: 'isWeekendView'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'reviewersIds'
      },
      {
        type: 'multipleChecklistDefinitionsIds'
      }
    ]
  },
  table_reviews_with_scores: {
    type: 'table_reviews_with_scores',
    description: <Trans i18nKey={WIDGETS_DESCRIPTIONS.TABLE_REVIEWS_WITH_SCORES.description} />,
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.TABLE_REVIEWS_WITH_SCORES.title} />,
    dateMode: 'clientInteraction',
    additionalDateMode: 'review',
    availableSizes: ['extra_large'],
    defaultViewMode: WIDGET_VIEW_MODES.QUESTIONS.value,
    defaultViewType: WIDGET_VIEW_MODES.PERCENTAGE.value,
    defaultWidgetSettings: {
      page: {
        number: 1,
        size: 10
      }
    },
    widget_names: [],
    availableFilters: [
      {
        type: 'clientInteractionDates'
      },
      {
        type: 'reviewDates'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'clientInteractionDirection'
      },
      {
        type: 'singleChecklistDefinitionId'
      }
    ]
  },
  table_reviews_count_by_operators_with_kpi: {
    type: 'table_reviews_count_by_operators_with_kpi',
    description: (
      <Trans i18nKey={WIDGETS_DESCRIPTIONS.TABLE_REVIEWS_COUNT_BY_OPERATORS_WITH_KPI.description} />
    ),
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.TABLE_REVIEWS_COUNT_BY_OPERATORS_WITH_KPI.title} />,
    dateMode: 'clientInteraction',
    availableSizes: ['extra_large'],
    defaultViewMode: 'table',
    widget_names: {
      table: ['checklist_definition_average_score_by_operators_history']
    },
    availableFilters: [
      {
        type: 'cutstomDates'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'requiredMultipleChecklistDefinitionsIds'
      },
      {
        type: 'kpiSettings'
      }
    ]
  },
  table_user_field_analytics: {
    type: 'table_user_field_analytics',
    description: <Trans i18nKey={WIDGETS_DESCRIPTIONS.TABLE_USER_FIELD_ANALYTICS.description} />,
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.TABLE_USER_FIELD_ANALYTICS.title} />,
    availableSizes: ['extra_large'],
    dateMode: 'default',
    defaultViewMode: 'table',
    availableFilters: [
      {
        type: 'unitsIds' // Проверяемый отдел
      },
      {
        type: 'operatorsIds' // Операторы
      },
      {
        type: 'reviewStatus' // Статус проверки
      },
      {
        type: 'multipleChecklistDefinitionsIds' // Выбор нескольких ФО
      },
      {
        type: 'dates' // Период / Отображать по дате: коммуниации/оценки
      },
      {
        type: 'communicationTypes' // Типы коммуникаций
      },
      {
        type: 'clientInteractionDirection' // Направление звонка
      },
      {
        type: 'userFields' // Пользовательские поля
      },
      {
        type: 'tasksDefinitions' // Теги
      }
    ]
  }
};
