import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { get, isEmpty } from 'lodash';
import { Col, Empty, Form, Input, Row, Tooltip, Typography } from 'antd';
import AiIcon from 'assets/images/ai-icon.svg';
import { isCustomFieldVisible } from 'core/utils/isCustomFieldVisible';
import { CUSTOM_FIELDS_TYPES } from '../../core/utils/constants';

const { Text } = Typography;

const CustomFieldsModal = ({
  customFields,
  customFieldsByKeys,
  isEditMode,
  setIsEditMode,
  fieldValues,
  setFieldValues
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const initialValues = {};
    Object.keys(customFields).forEach(item => {
      initialValues[item] = {
        value: customFields[item] || '',
        type: get(customFieldsByKeys, [item, 'fieldType']),
        id: get(customFieldsByKeys, [item, 'id'])
      };
    });
    setFieldValues(initialValues);
  }, [customFields, customFieldsByKeys, setFieldValues]);

  const handleChange = (item, value, isTypeChange = false) => {
    setFieldValues(prev => ({
      ...prev,
      [item]: isTypeChange ? { ...prev[item], type: value } : { ...prev[item], value }
    }));
    if (!isEditMode) {
      setIsEditMode(true);
    }
  };

  const customFieldsOptions = Object.values(CUSTOM_FIELDS_TYPES).filter(
    type => type !== CUSTOM_FIELDS_TYPES.SELECT && type !== CUSTOM_FIELDS_TYPES.ENUM
  );

  if (isEmpty(customFields))
    return (
      <Row justify="center" align="middle">
        <Col>
          <Empty />
        </Col>
      </Row>
    );

  return (
    <Form layout="horizontal" style={{ width: '100%' }}>
      <Row>
        {Object.keys(customFields).map(item => {
          const customFieldKey = item.replace('qolio_', '');
          if (!isCustomFieldVisible(customFieldsByKeys[customFieldKey])) {
            return '';
          }

          return (
            <Col span={24} key={customFieldKey}>
              <Form.Item labelAlign="left" style={{ margin: '4px' }}>
                <Row>
                  <Col span={8} flex="auto" style={{ alignItems: 'center', display: 'flex' }}>
                    {get(customFieldsByKeys, `${customFieldKey}.usedForAi`, false) === true && (
                      <img
                        src={AiIcon}
                        alt="ai-icon"
                        style={{ marginRight: '5px', paddingBottom: '2px' }}
                      />
                    )}
                    <Tooltip
                      title={get(customFieldsByKeys, `${customFieldKey}.name`, `${customFieldKey}`)}
                    >
                      <Text
                        strong
                        style={{
                          textOverflow: 'ellipsis',
                          width: '100%',
                          display: 'block',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden'
                        }}
                      >
                        {get(customFieldsByKeys, `${customFieldKey}.name`, `${customFieldKey}`)}
                      </Text>
                    </Tooltip>
                  </Col>
                  <Col span={24} flex="auto" style={{ display: 'flex' }}>
                    <Input.TextArea
                      style={{
                        width: '100%',
                        maxHeight: '100px',
                        minHeight: '32px',
                        height: '32px'
                      }}
                      value={get(fieldValues, [customFieldKey, 'value'], '')}
                      onChange={e => handleChange(item, e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          );
        })}
      </Row>
    </Form>
  );
};

export default CustomFieldsModal;
