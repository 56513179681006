import { DeleteOutlined, EditOutlined, ExportOutlined, FileAddOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, message, Modal, Tooltip } from 'antd';
import camelcase from 'camelcase';
import SText from 'components/Standard/SText';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SCard from 'components/Standard/SCard';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import { CHECKLIST_MANAGER_STATES, PERMISSIONS } from 'core/utils/constants';
import { get, intersection, isEmpty, isEqual } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reviewsResource } from 'redux/resources/reviews';
import { getChecklistsDefinitionsByIds } from 'redux/selectors/checklists';
import { getCurrentUser, getIsQolioAiUser } from 'redux/selectors/users';
import { openAddToCalibrationModal } from 'redux/ui/addToCalibrationModal/reducer';
import { clearCurrentChecklist, setChecklistManagerState } from 'redux/ui/checklistManager/reducer';
import { setCommunication } from 'redux/ui/clientInteractionDrawer/reducer';
import { updateTableRow } from 'redux/ui/clientInteractions/operations';
import { MoreVertical } from 'react-feather';
import Icon from 'components/Icon';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';

const ChecklistManagerHead = ({ id, history, communication, fromDrawer }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organization = useSelector(state => state.organizationInfo.organization, isEqual);

  const { checklistManagerState, currentChecklist } = useSelector(
    state => state.uiChecklistManager,
    isEqual
  );
  const checklistDefinitionsByIds = useSelector(
    state => getChecklistsDefinitionsByIds(state),
    isEqual
  );
  const currentUserPermissions = useSelector(
    state => get(getCurrentUser(state), 'role.permissions', []),
    isEqual
  );
  const reviewAuthorId = useSelector(state =>
    get(state.reviewsResource.byIds[id], 'reviewerId', '')
  );
  const checklistDefinitionId = get(currentChecklist, 'checklistDefinitionId');
  const checklistDefinition = get(checklistDefinitionsByIds, checklistDefinitionId, {});
  const currentUserId = useSelector(state => get(getCurrentUser(state), 'id', ''));
  const currentUser = useSelector(getCurrentUser, isEqual);
  const reviewAuthor = useSelector(state => state.usersResource.byIds[reviewAuthorId]);

  const isAbleToEdit = !isEmpty(
    intersection(currentUserPermissions, [PERMISSIONS.CAN_MAKE_REVIEW])
  );

  const { confirm } = Modal;

  const getHide = () => {
    const isAdmin = currentUser?.role['is-admin'];
    const isQolioAiUser = getIsQolioAiUser(reviewAuthor, organization);

    // Если текущий пользователь админ и проверяющий Qolio AI, отображаем кнопку удаления
    if (isAdmin && isQolioAiUser) {
      return false;
    }
    return currentUserId !== reviewAuthorId;
  };

  const DeleteReviewMenuItem = withConditionalRender({ hide: getHide() }, Menu.Item);

  const ChangeCurrentChecklistMenuItem = withConditionalRender(
    { hide: reviewAuthorId && currentUserId !== reviewAuthorId },
    Menu.Item
  );

  const NewReviewMenuItem = withConditionalRender(
    {
      hide: !reviewAuthorId,
      allowedPermissions: [PERMISSIONS.CAN_MAKE_REVIEW]
    },
    Menu.Item
  );

  const AddToCalibrationMenuItem = withConditionalRender(
    {
      hide: history.location.pathname.match(/calibration/),
      allowedPermissions: [PERMISSIONS.CAN_MANAGE_CALIBRATION_SESSIONS]
    },
    Menu.Item
  );

  const navigateToClientInteraction = () => {
    const clientInteractionTypeToLink = {
      chat: id => `/text-communications/${id}`,
      textCommunication: id => `/text-communications/${id}`,
      phoneCall: id => `/calls/${id}`,
      ticket: id => `/text-communications/${id}`,
      email: id => `/text-communications/${id}`
    };

    const { communicationType, clientInteractionType, id } = communication;

    return fromDrawer
      ? dispatch(setCommunication({ type: clientInteractionType, id }))
      : history.push(
          clientInteractionTypeToLink[camelcase(communicationType || clientInteractionType)](id)
        );
  };

  const addToCalibration = () => dispatch(openAddToCalibrationModal());

  const handleTableMenuClick = e => {
    switch (e.key) {
      case 'changeCurrentChecklist':
        return confirm({
          centered: true,
          title: t('components.checklistManager.checklistManagerHead.confirmChangeChecklist.title'),
          content: t(
            'components.checklistManager.checklistManagerHead.confirmChangeChecklist.description'
          ),
          okText: t('components.checklistManager.checklistManagerHead.confirmChangeChecklist.ok'),
          cancelText: t(
            'components.checklistManager.checklistManagerHead.confirmChangeChecklist.cancel'
          ),
          onOk: () => {
            dispatch(
              checklistDefinitionsResource.operations.loadWithInclude({
                pagination: false,
                sort: 'created_at',
                filters: { status: 'published' }
              })
            );
            dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.INITIAL));
            dispatch(clearCurrentChecklist());
          }
        });

      case 'navigateToClientInteraction':
        return navigateToClientInteraction();

      case 'addToCalibration':
        return addToCalibration();
      case 'deleteReview':
        return confirm({
          centered: true,
          title: t('components.checklistManager.checklistManagerHead.confirmDeleteChecklist.title'),
          content: t(
            'components.checklistManager.checklistManagerHead.confirmDeleteChecklist.description'
          ),
          okText: t('components.checklistManager.checklistManagerHead.confirmDeleteChecklist.ok'),
          cancelText: t(
            'components.checklistManager.checklistManagerHead.confirmDeleteChecklist.cancel'
          ),
          onOk: async () => {
            try {
              await dispatch(reviewsResource.operations.deleteById({ id }));

              if (fromDrawer) {
                dispatch(setCommunication({}));
                // * delete from table if needed
                dispatch(updateTableRow({ clientInteractionId: communication.id }));
              } else {
                history.push('/client-interactions');
              }

              message.success(
                t(
                  'components.checklistManager.checklistManagerHead.confirmDeleteChecklist.messages.reviewSuccessfullyDeleted'
                )
              );
            } catch (error) {
              console.log(error);
              message.error(
                t(
                  'components.checklistManager.checklistManagerHead.confirmDeleteChecklist.messages.deleteReviewFailed'
                )
              );
            }
          }
        });
      default:
        return '';
    }
  };

  const menu = (
    <Menu onClick={e => handleTableMenuClick(e)}>
      <ChangeCurrentChecklistMenuItem key="changeCurrentChecklist">
        <EditOutlined />
        <span>
          {t('components.checklistManager.checklistManagerHead.menu.changeCurrentChecklist')}
        </span>
      </ChangeCurrentChecklistMenuItem>
      <NewReviewMenuItem key="navigateToClientInteraction">
        <ExportOutlined />
        <span>
          {t('components.checklistManager.checklistManagerHead.menu.navigateToClientInteraction')}
        </span>
      </NewReviewMenuItem>
      <AddToCalibrationMenuItem key="addToCalibration">
        <FileAddOutlined />
        <span>{t('components.checklistManager.checklistManagerHead.menu.addToCalibration')}</span>
      </AddToCalibrationMenuItem>
      <DeleteReviewMenuItem key="deleteReview" style={{ color: 'var(--red_primary)' }}>
        <DeleteOutlined />
        <span>{t('components.checklistManager.checklistManagerHead.menu.deleteReview')}</span>
      </DeleteReviewMenuItem>
    </Menu>
  );

  const componentStates = {
    [CHECKLIST_MANAGER_STATES.INITIAL]: (
      <SRow backgroundColor="#FFFFFF">
        <SCol>
          <SText fontSize="14px" fontWeight={600} marginBottom="5px">
            {isAbleToEdit
              ? t('general.selectChecklist')
              : t('components.checklistManager.checklistManagerHead.buttons.emptyReview')}
          </SText>
        </SCol>
      </SRow>
    ),
    [CHECKLIST_MANAGER_STATES.EDITING]: (
      <SRow type="flex" style={{ marginBottom: 0 }}>
        <SCol span={24}>
          <SCard bordered shadowed={false}>
            <SRow type="flex" align="middle" justify="space-between" wrap={false}>
              <SCol lg={21} xl={22} flex="auto">
                <Tooltip title={checklistDefinition?.name}>
                  <SText
                    fontSize="20px"
                    {...(!isEmpty(checklistDefinition)
                      ? { ellipsis: true, width: '100%' }
                      : { textWrap: 'word-break' })}
                  >
                    {!isEmpty(checklistDefinition)
                      ? checklistDefinition?.name
                      : t(
                          'components.checklistManager.checklistManagerHead.emptyEditingChecklistDefinition.title'
                        )}
                  </SText>
                </Tooltip>
              </SCol>
              <SCol display="flex" alignItems="center" flex="none">
                <Dropdown
                  overlay={menu}
                  trigger={['click']}
                  placement="bottomLeft"
                  getPopupContainer={node => node.parentNode}
                >
                  <Button
                    type="link"
                    size="small"
                    icon={<Icon icon={MoreVertical} color="var(--blue_2)" size="24px" />}
                    strokeWidth="1"
                  />
                </Dropdown>
              </SCol>
            </SRow>
          </SCard>
        </SCol>
      </SRow>
    ),
    [CHECKLIST_MANAGER_STATES.SAVED]: (
      <SRow type="flex" style={{ marginBottom: 0 }} backgroundColor="#FFFFFF">
        <SCol span={24}>
          <SCard bordered shadowed>
            <SRow type="flex" align="middle" justify="space-between" wrap={false}>
              <SCol lg={21} xl={22} flex="auto">
                <Tooltip title={checklistDefinition?.name}>
                  <SText width="100%" fontSize="20px" ellipsis>
                    {`${checklistDefinition?.name}`}
                  </SText>
                </Tooltip>
              </SCol>
              {(isAbleToEdit || currentUserId === reviewAuthorId) && (
                <SCol display="flex" alignItems="center" flex="none">
                  <Dropdown
                    overlay={menu}
                    trigger={['click']}
                    placement="bottomLeft"
                    getPopupContainer={node => node.parentNode}
                  >
                    <Button
                      type="link"
                      size="small"
                      icon={<Icon icon={MoreVertical} color="var(--blue_2)" size="24px" />}
                      strokeWidth="1"
                    />
                  </Dropdown>
                </SCol>
              )}
            </SRow>
          </SCard>
        </SCol>
      </SRow>
    )
  };

  return (
    <SRow>
      <SCol span={24}>{componentStates[checklistManagerState]}</SCol>
    </SRow>
  );
};

export default React.memo(withRouter(ChecklistManagerHead));
