import { Col, Row, Skeleton } from 'antd';
import CommentsList from 'components/Comments/CommentsList/CommentsList';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import { CHECKLIST_MANAGER_STATES, PERMISSIONS } from 'core/utils/constants';
import { get, isEmpty, isEqual } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CreateCommentForm from './CreateCommentForm';
import { getCurrentUser } from '../../../redux/selectors/users';
import { useSelector } from 'react-redux';

const CommentsPanel = ({
  loading,
  comments = [],
  showCommentsList,
  allowCommenting = true,
  allowActions = true,
  allowSelectTimestamp = true,
  addingComment,
  onAddCommentButton,
  onCancelComment,
  onDeleteComment,
  onUpdateComment,
  onCommentSave,
  seconds,
  isQuestionView,
  id,
  communication
}) => {
  const ConditionalAddCommentButton = withConditionalRender(
    { allowedPermissions: [PERMISSIONS.CAN_MAKE_REVIEW] },
    SButton
  );
  const canCommentAnyCommunications = useSelector(state =>
    get(getCurrentUser(state), 'role.permissions', []).includes(PERMISSIONS.CAN_REPLY_ALL_COMMENTS)
  );
  const { t } = useTranslation();

  const { checklistManagerState } = useSelector(state => state.uiChecklistManager, isEqual);

  const currentChecklist = useSelector(state => state.uiChecklistManager.currentChecklist);

  const phoneCallsResource = useSelector(state => state.phoneCallsResource.byIds);
  // Тип коммуникации когда коммуникация открыта в новой вкладке
  const communicationType = Object.values(phoneCallsResource)[0]?.type;

  // Тип коммуникации если октрывается через окошко сбоку
  const drawerCommunicationType = useSelector(
    state => state.uiClientInteractionDrawer.communication.type
  );

  if (loading) {
    return (
      <Row style={{ background: 'white' }}>
        <Skeleton active />
      </Row>
    );
  }

  const commentsPanel = () => {
    // Когда коммуникация открыта в СК - Если не выбран чеклист и тип коммуникации равен phone_call не отображаем кнопку Добавить комментарий под плеером
    if (isEmpty(currentChecklist) && drawerCommunicationType === 'phone_call') {
      return '';
    }
    // Когда коммуникация открыта в новой вкладке - Если не выбран чеклист и тип коммуникации равен phone-calls не отображаем кнопку Добавить комментарий под плеером
    if (isEmpty(currentChecklist) && communicationType === 'phone-calls') {
      return '';
    }
    // Если это чат и кнопка редактирования нажата отображаем область ввода комментария
    if (
      communication &&
      communication?.clientInteractionType === 'custom_communication' &&
      checklistManagerState === CHECKLIST_MANAGER_STATES.EDITING
    ) {
      return (
        <SCol span={24}>
          <CreateCommentForm
            onCancel={onCancelComment}
            onCommentSave={onCommentSave}
            allowSelectTimestamp={allowSelectTimestamp}
            seconds={seconds}
            id={id}
          />
        </SCol>
      );
    }
    // Если добавляем комментарий отображаем область добавления комментария
    if (addingComment) {
      return (
        <SCol span={24}>
          <CreateCommentForm
            onCancel={onCancelComment}
            onCommentSave={onCommentSave}
            allowSelectTimestamp={allowSelectTimestamp}
            seconds={seconds}
            id={id}
          />
        </SCol>
      );
    }
    // Если это чат и кнопка редактирования не нажата ничего не отображаем
    if (communication && communication?.clientInteractionType === 'custom_communication') {
      return '';
    }
    // Кнопка добавления комментария
    return (
      <SCol span={24}>
        <SCard bordered shadowed>
          <ConditionalAddCommentButton
            size="large"
            boxShadow="none"
            fontSize="16px"
            height="40px"
            width="100%"
            border="1px dashed var(--blue_2)"
            color="var(--blue_6)"
            block
            onClick={onAddCommentButton}
          >
            {t('components.commentsPanel.buttons.addComment')}
          </ConditionalAddCommentButton>
        </SCard>
      </SCol>
    );
  };

  return (
    <>
      {showCommentsList && (
        <Row>
          <Col span={24}>
            <CommentsList
              isQuestionView={isQuestionView}
              allowActions={allowActions}
              onDelete={onDeleteComment}
              onUpdate={onUpdateComment}
              comments={comments}
              loading={loading}
            />
          </Col>
        </Row>
      )}

      {(allowCommenting || canCommentAnyCommunications) && (
        <Row>
          <SCol span={24} padding="0px" marginTop={!isEmpty(comments) ? '8px' : '0'}>
            <Row>{commentsPanel()}</Row>
          </SCol>
        </Row>
      )}
    </>
  );
};

export default CommentsPanel;
