import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { selectSearch } from 'core/utils/selectSearch';
import { useDispatch, useSelector } from 'react-redux';
import { Col, message, Select } from 'antd';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import Text from 'antd/lib/typography/Text';
import { userCustomFieldsResource } from '../redux/resources/userCustomFields';
import { updateUiWidgetFilters } from '../redux/ui/widgetSettingsModal/reducer';

const { Option } = Select;

const FilterContainer = styled.div`
  width: 100%;
  margin: 8px 0;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
`;

const WidgetUserFields = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { filters } = useSelector(state => state.uiWidgetSettingsModal);
  const customFields = useSelector(state => Object.values(state.userCustomFieldsResource.byIds));
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );

  useEffect(() => {
    if (isEmpty(customFields)) {
      dispatch(
        userCustomFieldsResource.operations.load({
          organization_id: organizationId,
          pagination: false,
          sort: '-created_at'
        })
      );
    }
  }, []);

  return (
    <Col span={24}>
      <Text strong>
        Пользовательские поля
        <Text type="danger">{t('dashboardPage.widget.modal.messages.requiredField')}</Text>
      </Text>
      <FilterContainer>
        <StyledSelect
          disabled={false}
          mode="multiple"
          allowClear
          optionLabelProp="label"
          placeholder="Пользовательские поля"
          value={filters?.userFields || []}
          maxTagCount={0}
          maxTagPlaceholder={selectedKeys => `Полей: ${selectedKeys.length}`}
          onChange={userFields => {
            if (userFields?.length <= 10) {
              return dispatch(updateUiWidgetFilters({ userFields }));
            }
            return message.info('Можно выбрать не более 10 пользовательских полей');
          }}
          filterOption={(input, option) => selectSearch({ input, option, searchProp: 'children' })}
        >
          {customFields.map(item => (
            <Option value={item.id} key={item.id} label={item.name}>
              {t(item.name)}
            </Option>
          ))}
        </StyledSelect>
      </FilterContainer>
    </Col>
  );
};

export default WidgetUserFields;
