import { DownOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Menu, message } from 'antd';
import { PERMISSIONS } from 'core/utils/constants';
import { get, isEmpty, some, sortBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { clientInteractionsResource } from 'redux/resources/clientInteractions';
import truncateString from 'core/utils/truncateString';
import { statusesResource } from 'redux/resources/status';
import { getCurrentUser } from 'redux/selectors/users';

const overlayClassName = 'status-dropdown';

const StatusSelector = ({
  canManageOrganization,
  canAddStatus = canManageOrganization,
  clientInteractionsByIds,
  updateClientInteraction,
  createStatus,
  statusesByIds = {},
  call,
  loadStatuses,
  loadingStatuses,
  updatingStatus,
  canMakeReview,
  allowLoadStatuses = true
}) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const updateDropdownState = e => {
    try {
      const path = e.path || e.composedPath();

      const shouldBeVisible = some(
        path,
        ({ className }) => typeof className === 'string' && className?.includes(overlayClassName)
      );

      if (shouldBeVisible) return;
      return setDropdownVisible(false);
    } catch (error) {
      console.log(error);
      return setDropdownVisible(false);
    }
  };

  useEffect(() => {
    if (canMakeReview && !loadingStatuses && allowLoadStatuses) {
      loadStatuses();
    }

    if (canAddStatus) {
      window.addEventListener('click', updateDropdownState);
    }

    return () => {
      if (canAddStatus) {
        window.removeEventListener('click', updateDropdownState);
      }
    };
  }, []);

  if (!canMakeReview) {
    return null;
  }

  const clientInteraction = clientInteractionsByIds[call.id];

  const status = statusesByIds[clientInteraction?.statusId];
  const statuses = sortBy(Object.values(statusesByIds), 'createdAt');

  const onSelectStatus = async ({ key: statusId }) => {
    await updateClientInteraction({ id: call.id, statusId });
    setDropdownVisible(false);
  };

  const onCreateStatus = async () => {
    if (!inputRef.current?.state?.value || !inputRef.current.state.value.trim()) {
      return message.warning(t('components.statusSelector.blankNameError'));
    }

    const newStatus = await createStatus({ name: inputRef.current.state.value });

    if (!newStatus) {
      return;
    }

    inputRef.current.state.value = '';
    await onSelectStatus({ key: newStatus.id });
    setDropdownVisible(false);
  };

  const menu = (
    <Menu selectedKeys={status?.id} onClick={onSelectStatus}>
      {statuses.map(({ id, name }) => (
        <Menu.Item
          key={id}
          disabled={updatingStatus}
          style={{ maxWidth: '364px', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {name}
        </Menu.Item>
      ))}

      {!isEmpty(status) && (
        <Menu.Item key={undefined} disabled={updatingStatus}>
          {t('components.statusSelector.resetStatus')}
        </Menu.Item>
      )}

      {canAddStatus && <Menu.Divider />}

      {/* TODO: move container to common components */}
      {canAddStatus && (
        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: '4px 12px' }}>
          <Input
            placeholder={t('components.statusSelector.newStatusNamePlaceholder')}
            disabled={updatingStatus}
            ref={inputRef}
          />

          <Button
            type="link"
            icon={<PlusOutlined />}
            disabled={updatingStatus}
            loading={updatingStatus}
            onClick={onCreateStatus}
          >
            {t('general.add')}
          </Button>
        </div>
      )}
    </Menu>
  );

  const dropdownText =
    truncateString(status?.name, 13) || t('components.statusSelector.selectStatus');

  const onLinkClick = e => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownVisible(!dropdownVisible);
  };

  const Icon = loadingStatuses ? LoadingOutlined : DownOutlined;

  return (
    <Dropdown
      overlay={menu}
      overlayClassName={overlayClassName}
      disabled={loadingStatuses}
      placement="bottomRight"
      trigger={['click']}
      {...(canAddStatus ? { visible: dropdownVisible } : {})}
    >
      <a className="ant-dropdown-link" onClick={onLinkClick}>
        {`${dropdownText} `}
        <Icon />
      </a>
    </Dropdown>
  );
};

const mapStateToProps = state => ({
  // TODO : move to utils o
  canManageOrganization: get(getCurrentUser(state), 'role.permissions', []).includes(
    PERMISSIONS.CAN_MANAGE_ORGANIZATION_STRUCTURE
  ),
  canMakeReview: get(getCurrentUser(state), 'role.permissions', []).includes(
    PERMISSIONS.CAN_MAKE_REVIEW
  ),
  loadingStatuses: state.statusesResource.loading,
  statusesByIds: state.statusesResource.byIds,
  clientInteractionsByIds: state.clientInteractionsResource.byIds,
  updatingStatus:
    state.clientInteractionsResource.updateByIdStarted || state.statusesResource.createStarted
});

const mapDispatchToProps = {
  loadStatuses: statusesResource.operations.load,
  updateClientInteraction: clientInteractionsResource.operations.updateById,
  createStatus: statusesResource.operations.create
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusSelector);
