export default {
  templates: {
    templates: 'Шаблоны',
    addTemplate: 'Добавить шаблон',
    loadingTemplates: 'Загружаем шаблоны...😤',
    empty: 'Нет шаблонов',
    confirmDelete: 'Удалить шаблон?',
    searchTemplate: 'Найти шаблон'
  }
};
