import { Collapse, Row } from 'antd';
import ColumnTitle from 'components/ColumnTitle';
import { COLUMN_TYPES } from 'core/utils/constants';
import { difference, isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { getCustomFieldsByKeysForReviews } from 'redux/selectors/customFields';
import { addColumn } from 'redux/ui/clientInteractions/reducer';
import { useTranslation } from 'react-i18next';
import { customFieldsResource } from 'redux/resources/customFields';
import Text from 'antd/lib/typography/Text';
import { AddColumnIcon, ColumnPreview } from './styled';
import QuestionColumn from '../../../../components/QuestionColumn';

const { Panel } = Collapse;

const CustomFieldsColumns = ({
  tableColumns,
  addColumn,
  customFieldsByKeys = {},
  checklistDefinitionsIds = {},
  questionsChecklist = {},
  loadCustomFields,
  searchQuery,
  currentPage,
  setCurrentPage,
  totalPages,
  setTotalPages
}) => {
  const { t } = useTranslation();
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );

  const [loadingMore, setLoadingMore] = useState(false);
  const scrollContainerRef = useRef(null);

  const allColumnKeys = [
    ...Object.keys(COLUMN_TYPES),
    ...Object.keys(customFieldsByKeys),
    ...Object.keys(checklistDefinitionsIds)
  ];

  const availableColumns = difference(allColumnKeys, tableColumns);

  const getColumnTitle = column => {
    return questionsChecklist[column]
      ? questionsChecklist[column]?.name
      : t(COLUMN_TYPES[column]?.name) ||
          customFieldsByKeys[column]?.name ||
          checklistDefinitionsIds[column]?.name ||
          '';
  };

  const filteredColumns = availableColumns.filter(column =>
    getColumnTitle(column)
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const customFieldsColumns = filteredColumns.filter(column => column.includes('custom_field/'));

  const loadMore = async () => {
    if (loadingMore) {
      return;
    }

    if (currentPage === totalPages) {
      return;
    }

    setLoadingMore(true);
    try {
      const response = await loadCustomFields({
        organization_id: organizationId,
        page_number: currentPage + 1,
        page_size: 10
      });

      if (isEmpty(totalPages)) {
        setTotalPages(response?.meta?.totalPages);
      }

      setCurrentPage(prevPage => prevPage + 1);
    } finally {
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 5 && !loadingMore) {
          loadMore();
        }
      }
    };

    const currentContainer = scrollContainerRef.current;
    if (currentContainer) {
      currentContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loadingMore, currentPage]);

  return (
    <div ref={scrollContainerRef} style={{ overflowY: 'auto', maxHeight: '400px' }}>
      <Row type="flex" align="middle" justify="space-between" style={{ height: '30px' }}>
        <Text strong>Кастомные поля</Text>
      </Row>

      {customFieldsColumns.map(column => (
        <Row key={column} gutter={[8, 8]} style={{ margin: '-4px 0' }}>
          {checklistDefinitionsIds[column] ? (
            <Collapse
              defaultActiveKey={['1']}
              style={{ width: '100%', margin: '4px 0' }}
              expandIconPosition="right"
            >
              <Panel
                key={column}
                header={
                  <ColumnTitle
                    columnType={column}
                    customFieldsByKeys={customFieldsByKeys}
                    checklistDefinitionsIds={checklistDefinitionsIds}
                    questionsChecklist={questionsChecklist}
                  />
                }
              >
                {checklistDefinitionsIds[column] && (
                  <QuestionColumn
                    checklistDefinition={checklistDefinitionsIds[column]}
                    addColumn={addColumn}
                    tableColumns={tableColumns}
                  />
                )}
              </Panel>
            </Collapse>
          ) : (
            <ColumnPreview span={24} onClick={() => addColumn(column)}>
              <ColumnTitle
                columnType={column}
                customFieldsByKeys={customFieldsByKeys}
                checklistDefinitionsIds={checklistDefinitionsIds}
                questionsChecklist={questionsChecklist}
                width="80%"
              />
              <AddColumnIcon />
            </ColumnPreview>
          )}
        </Row>
      ))}

      {currentPage === totalPages && (
        <Row type="flex" align="middle" justify="center" style={{ height: '30px' }}>
          <Text strong>Все поля загружены</Text>
        </Row>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    customFieldsByKeys: getCustomFieldsByKeysForReviews(state, true),
    tableColumns: state.uiClientInteractions.tableColumns,
    checklistDefinitionsIds: state.checklistDefinitionsResource.byIds,
    questionsChecklist: state.questionsResource.byIds
  };
};

const mapDispatchToProps = {
  addColumn,
  loadCustomFields: customFieldsResource.operations.load
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldsColumns);
