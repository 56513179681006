import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Button, DatePicker, TimePicker } from 'antd';

const AppMenuItem = styled.div`
  margin: 0 10px;
  position: relative;
`;

const Tooltip = styled.div`
  z-index: 998;
  position: absolute;
  right: -125%;
  //right: -90%;
  transform: translateX(-50%);
  background-color: #00152a;
  color: rgba(255, 255, 255, 0.65);
  border-radius: 5px;
  width: 700px;
  display: ${props => (props.isVisible ? 'block' : 'none')};
  transform-origin: top;
  padding: 15px;
`;

const TooltipText = styled.div`
  margin: 0 0 10px 0;
  display: block;
  color: #fff;
  height: auto;
  line-height: 20px;

  &:last-child {
    margin: 0;
  }
`;

const TimeContainer = styled.span`
  border: 2px solid #fff;
  border-radius: 20px;
  padding: 5px;
  color: #fff;
`;

const InfoContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 20px;
`;

const InfoContentItems = styled.div`
  display: flex;
  align-items: center;
  gap: 0 20px;
`;

const InfoContentItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContentTitle = styled.div``;
const InfoContentData = styled.div``;
const StyledHr = styled.hr``;

const EndDayContent = styled.div`
  display: flex;
  justify-content: right;
`;

const EndDayItems = styled.div`
  display: flex;
  align-items: center;
`;

const EndDayItem = styled.div``;

const PauseDayContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PauseDayTitle = styled.div``;

const PauseDayItems = styled.div`
  display: flex;
  align-items: center;
`;

const PauseDayItem = styled.div``;

const EndDayButton = styled.div``;

const TotalTimeMenu = ({ startDay, setStartDay }) => {
  const [currentTime, setCurrentTime] = useState(moment().startOf('day'));
  // const [startDay, setStartDay] = useState(true);
  const [tooltipVisible, setTooltipVisible] = useState(true);
  const [selectedDate, setSelectedDate] = useState(moment().startOf('day'));
  const [selectedTime, setSelectedTime] = useState(moment().startOf('minute'));

  useEffect(() => {
    let timer;

    if (startDay) {
      timer = setInterval(() => {
        setCurrentTime(prevTime => prevTime.clone().add(1, 'second'));
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [startDay]);

  const handleToggleDay = () => {
    setStartDay(prev => !prev);
  };

  return (
    <AppMenuItem
    // onMouseEnter={() => setTooltipVisible(true)}
    // onMouseLeave={() => setTooltipVisible(false)}
    >
      {/*<TimeContainer>{currentTime.format('HH:mm:ss')}</TimeContainer>*/}
      <Tooltip isVisible={tooltipVisible}>
        {startDay ? (
          <>
            <InfoContent>
              <InfoContentItems>
                <InfoContentItem>
                  <InfoContentTitle>Дата</InfoContentTitle>
                  <InfoContentData>Пт 20.12.2024</InfoContentData>
                </InfoContentItem>
                <InfoContentItem>
                  <InfoContentTitle>Начало дня</InfoContentTitle>
                  <InfoContentData>14:41</InfoContentData>
                </InfoContentItem>
                <InfoContentItem>
                  <InfoContentTitle>Сумма затрат</InfoContentTitle>
                  <InfoContentData>00:00</InfoContentData>
                </InfoContentItem>
                <InfoContentItem>
                  <InfoContentTitle>Окончание дня</InfoContentTitle>
                  <InfoContentData>12:21</InfoContentData>
                </InfoContentItem>
              </InfoContentItems>
              <EndDayButton>
                <Button type="primary" onClick={handleToggleDay}>
                  Закончить день
                </Button>
              </EndDayButton>
            </InfoContent>
            <StyledHr />
            <EndDayContent>
              <EndDayItems>
                <EndDayItem>Окончание дня</EndDayItem>
                <TimePicker
                  value={selectedTime}
                  format="HH:mm"
                  onChange={time => setSelectedTime(time)}
                  inputReadOnly
                  allowClear={false}
                  style={{ width: '80px', zIndex: 999, marginLeft: '10px' }}
                />
              </EndDayItems>
            </EndDayContent>
            <StyledHr />
            <PauseDayContent>
              <PauseDayTitle>Добавить</PauseDayTitle>
              <PauseDayItems>
                <PauseDayItem>Перерыв/простой</PauseDayItem>
                <TimePicker
                  value={selectedTime}
                  format="HH:mm"
                  onChange={time => setSelectedTime(time)}
                  inputReadOnly
                  allowClear={false}
                  style={{ width: '80px', zIndex: 999, marginLeft: '10px' }}
                />
              </PauseDayItems>
            </PauseDayContent>
          </>
        ) : (
          <>
            <TooltipText>Откройте рабочий день</TooltipText>
            <TooltipText>
              Для доступа в интерфейс укажите дату
              <DatePicker
                value={selectedDate}
                format="DD/MM/YYYY"
                onChange={date => setSelectedDate(date)}
                inputReadOnly
                allowClear={false}
                style={{ width: '120px', zIndex: 999, marginLeft: '10px' }}
              />
            </TooltipText>
            <TooltipText>
              И время начала рабочего дня
              <TimePicker
                value={selectedTime}
                format="HH:mm"
                onChange={time => setSelectedTime(time)}
                inputReadOnly
                allowClear={false}
                style={{ width: '80px', zIndex: 999, marginLeft: '10px' }}
              />
            </TooltipText>
            <TooltipText>
              <Button type="primary" onClick={handleToggleDay}>
                Открыть день
              </Button>
            </TooltipText>
          </>
        )}
      </Tooltip>
    </AppMenuItem>
  );
};

export default TotalTimeMenu;
