import React, { useEffect, useState } from 'react';
import { Col, Modal } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WarningFilled } from '@ant-design/icons';
import { getCurrentUser } from '../../redux/selectors/users';
import SRow from '../Standard/SRow';
import SCol from '../Standard/SCol';
import SText from '../Standard/SText';

// activeUsersCount - текущие количество активных пользователей
// activeUsersCountLimit - лимит активных пользователей
// licenseExpirationDate - срок действия лицензии
// LimitActiveUsersExceeded - true/false, флаг отвечающий за превышение лимита активных пользователей
// isLicenseExpired - true/false, флаг отвечающий за истечение лимита лицензии

const ActivityUsersControl = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { organization, loading: organizationLoading } = useSelector(
    state => state.organizationInfo
  );
  const currentUserId = useSelector(state => get(getCurrentUser(state), 'id', ''));
  const isLoaded = !organizationLoading && !isEmpty(organization);

  const { activeUsersCount, activeUsersCountLimit, licenseExpirationDate } = organization;
  const expirationDate = licenseExpirationDate
    ? new Date(
        new Date(licenseExpirationDate).setDate(new Date(licenseExpirationDate).getDate() + 1)
      )
    : null;
  const currentDate = new Date();
  const isLicenseExpired = expirationDate ? currentDate > expirationDate : true;
  const LimitActiveUsersExceeded = activeUsersCount > activeUsersCountLimit;

  useEffect(() => {
    if (!isLicenseExpired && !LimitActiveUsersExceeded) {
      return;
    }
    if (!isLoaded) {
      return;
    }
    setVisible(true);

    // Функция, вызываемая при изменении местоположения
    const unlisten = history.listen(location => {
      const href = `user/${currentUserId}/organization-settings/employees`;
      if (location.pathname.includes(href)) {
        setVisible(false);
      } else if (isLicenseExpired || LimitActiveUsersExceeded) {
        setVisible(true);
      }
    });

    return () => {
      unlisten();
    };
  }, [history, currentUserId, isLicenseExpired, LimitActiveUsersExceeded, isLoaded]);

  const handleRedirect = () => {
    setVisible(false);
    const href = `/user/${currentUserId}/organization-settings/employees`;
    history.push(href);
  };

  const modalText = () => {
    if (isLicenseExpired)
      return t('organizationStructure.tabs.employees.isLimitExpired.isLicenseExpired.text');

    if (LimitActiveUsersExceeded)
      return t('organizationStructure.tabs.employees.isLimitExpired.usersCountLimit.text');
  };

  const deactivateUsers = count => {
    let word;
    const lastDigit = count % 10;
    const lastTwoDigits = count % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      word = t('organizationStructure.tabs.employees.isLimitExpired.usersCountLimit.users'); // Если число заканчивается на 11-19
    } else if (lastDigit === 1) {
      word = t('organizationStructure.tabs.employees.isLimitExpired.usersCountLimit.user'); // Если число заканчивается на 1
    } else if (lastDigit >= 2 && lastDigit <= 4) {
      word = t('organizationStructure.tabs.employees.isLimitExpired.usersCountLimit.user'); // Если число заканчивается на 2-4
    } else {
      word = t('organizationStructure.tabs.employees.isLimitExpired.usersCountLimit.users'); // Во всех остальных случаях
    }

    return word;
  };

  const modalDescription = () => {
    if (isLicenseExpired)
      return t('organizationStructure.tabs.employees.isLimitExpired.isLicenseExpired.description');

    if (LimitActiveUsersExceeded)
      return `${t(
        'organizationStructure.tabs.employees.isLimitExpired.usersCountLimit.descriptionStart'
      )} ${activeUsersCount - activeUsersCountLimit} ${deactivateUsers(
        activeUsersCount - activeUsersCountLimit
      )} ${t(
        'organizationStructure.tabs.employees.isLimitExpired.usersCountLimit.descriptionEnd'
      )}`;
  };

  return (
    <Modal
      visible={isLoaded && visible}
      destroyOnClose
      onCancel={handleRedirect}
      onOk={handleRedirect}
      width={612}
      footer={null}
      centered
      className="limit-modal"
    >
      <SRow marginTop="9px">
        <SCol span={24} display="flex" justifyContent="center">
          <WarningFilled color="#FFC53D" style={{ fontSize: 70, color: '#FFC53D' }} />
        </SCol>
      </SRow>
      <SRow marginTop="33px">
        <SCol span={24} display="flex" justifyContent="center" style={{ textAlign: 'center' }}>
          <SText fontSize="20px" fontWeight="600">
            {modalText()}
          </SText>
        </SCol>
      </SRow>
      <SRow marginTop="12px">
        <SCol span={24} display="flex" justifyContent="center" style={{ textAlign: 'center' }}>
          {modalDescription()}
        </SCol>
      </SRow>
      <SRow marginTop="24px">
        <SCol span={24} display="flex" justifyContent="center" style={{ textAlign: 'center' }}>
          <Col>
            <Text>Контакты для связи:</Text>
            <Col>
              {'Телеграмм: '}
              <a href="https://t.me/qolio_help_bot" target="_blank" rel="noopener noreferrer">
                https://t.me/qolio_help_bot
              </a>
            </Col>
            <Col>
              {'Почта: '}
              <a href="#">support@qolio.ru</a>
            </Col>
          </Col>
        </SCol>
      </SRow>
    </Modal>
  );
};

export default ActivityUsersControl;
