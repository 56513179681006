export default {
  userSettingsPage: {
    categories: {
      settings: 'Ajustes',
      main: 'El principal'
    },
    title: 'Configuración de perfil',
    tabs: {
      info: {
        tabName: 'Información',
        form: {
          registrationDate: 'Fecha de registro',
          companyName: 'Nombre de la compañía',
          role: 'Papel',
          unit: 'Departamento'
        }
      },
      accountSettings: {
        tabName: 'Ajustes de la cuenta',
        form: {
          lastName: 'Apellido',
          firstAndMiddleName: 'Nombre y patronímico',
          email: 'E-mail',
          phoneNumber: 'Número de teléfono',
          locale: 'Idioma del interfaz',
          telegram: 'Telegram'
        },
        avatar: {
          tabName: 'Avatar',
          updaloadAvatarlabel: 'Pulse para elegir una foto',
          avatarPreview: 'Revisión previa: ',
          buttons: {
            loadAvatar: 'Descargar la foto',
            deleteAvatar: 'Borrar la foto'
          }
        }
      },
      securitySettings: {
        tabName: 'Ajustes de acceso',
        form: {
          newPassword: 'Nueva clave',
          newPasswordConfirmation: 'Confirmación de la clave'
        }
      }
    },
    buttons: { update: 'Renovar datos' },
    messages: {
      dataSuccessfullyUpdated: 'Los datos se han renovado',
      updateDataFailed: 'No se han renovado los datos',
      passwordSuccessfullyUpdated: 'Clave renovada',
      editingAnotherUsersSettingsPT1: 'Atención! Usted está redactanto los ajustes del usuario',
      editingAnotherUsersSettingsPT2:
        'para redactar su página, pase al menú (en la parte superior derecha) y elija el punto de ajuste.',
      form: {
        validateMessages: {
          enterLastName: 'Por favor introduzca el apellido',
          enterFirstAndMiddleName: 'Por favor ingrese un nombre',
          enterPassword: 'Por fsavor introduzca su clave',
          enterPasswordConfirmation: 'Por favor reconfirme su clave',
          passwordMismathing: 'Las claves no concuerdan',
          passwordLenghtMismathing: 'La contraseña debe tener más de 6 caracteres'
        }
      }
    },
    organization: {
      title: 'Información de uso',
      currentPlanSlug: 'Tarifa actual',
      registrationDate: 'Fecha de registro',
      registeredUsers: 'Registrado por el usuario',
      monthReviewsCount: 'Número de cheques para el mes actual'
    }
  }
};
