import React from 'react';
import { Typography } from 'antd';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { PERMISSIONS, PERMISSIONS_DESCRIPTIONS } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';

export const AnalitycsAndReviewsPermissions = ({ fields, getCheckboxWithTooltip, getCheckbox }) => {
  const { t } = useTranslation();
  const { Text } = Typography;

  return (
    <SRow gutter={[8, 8]} type="flex">
      <SCol span={24}>
        <Text strong>{t('organizationStructure.tabs.roles.modal.form.analyticsCategory')}</Text>
      </SCol>

      <SCol span={24}>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_SEE_UNIT_CLIENT_INTERACTIONS,
            t(PERMISSIONS_DESCRIPTIONS.CAN_SEE_UNIT_CLIENT_INTERACTIONS.title),
            [],
            fields[PERMISSIONS.CAN_DOWNLOAD_CLIENT_INTERACTION_MEDIA]
          )}
        </SRow>
        <SRow>
          {getCheckboxWithTooltip(
            PERMISSIONS.CAN_MAKE_REVIEW,
            t(PERMISSIONS_DESCRIPTIONS.CAN_MAKE_REVIEW.title),
            '',
            [],
            fields[PERMISSIONS.CAN_SEE_AND_MANAGE_HIDDEN_COMMENTS]
          )}
        </SRow>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_UPDATE_CLIENT_INTERACTION_OPERATOR,
            t(PERMISSIONS_DESCRIPTIONS.CAN_UPDATE_CLIENT_INTERACTION_OPERATOR.title)
          )}
        </SRow>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_DOWNLOAD_CLIENT_INTERACTION_MEDIA,
            t(PERMISSIONS_DESCRIPTIONS.CAN_DOWNLOAD_CLIENT_INTERACTION_MEDIA.title),
            [PERMISSIONS.CAN_SEE_UNIT_CLIENT_INTERACTIONS]
          )}
        </SRow>
        <SRow>
          {getCheckboxWithTooltip(
            PERMISSIONS.CAN_SEE_AND_MANAGE_HIDDEN_COMMENTS,
            t(PERMISSIONS_DESCRIPTIONS.CAN_SEE_AND_MANAGE_HIDDEN_COMMENTS.title),
            '',
            [PERMISSIONS.CAN_MAKE_REVIEW]
          )}
        </SRow>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_SEE_UNIT_REVIEWS,
            t(PERMISSIONS_DESCRIPTIONS.CAN_SEE_UNIT_REVIEWS.title)
          )}
        </SRow>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_SEE_REVIEWER_NAME,
            t(PERMISSIONS_DESCRIPTIONS.CAN_SEE_REVIEWER_NAME.title)
          )}
        </SRow>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_ACCESS_TEAM_PAGE,
            t(PERMISSIONS_DESCRIPTIONS.CAN_ACCESS_TEAM_PAGE.title)
          )}
        </SRow>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_SEE_DASHBOARDS,
            t(PERMISSIONS_DESCRIPTIONS.CAN_SEE_DASHBOARDS.title)
          )}
        </SRow>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_SEE_UNIT_OPERATORS_ANALYTICS_PAGE,
            t(PERMISSIONS_DESCRIPTIONS.CAN_SEE_UNIT_OPERATORS_ANALYTICS_PAGE.title),
            [],
            fields[PERMISSIONS.CAN_MANAGE_ORGANIZATION_STRUCTURE]
          )}
        </SRow>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_MANAGE_CALIBRATION_SESSIONS,
            t(PERMISSIONS_DESCRIPTIONS.CAN_MANAGE_CALIBRATION_SESSIONS.title)
          )}
        </SRow>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_MANAGE_WORK_PLAN_TASK_CONFIGURATIONS,
            t(PERMISSIONS_DESCRIPTIONS.CAN_MANAGE_WORK_PLAN_TASK_CONFIGURATIONS.title)
          )}
        </SRow>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_EDIT_REVIEW,
            t(PERMISSIONS_DESCRIPTIONS.CAN_EDIT_REVIEW.title)
          )}
        </SRow>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_SEE_CHATGPT,
            t(PERMISSIONS_DESCRIPTIONS.CAN_SEE_CHATGPT.title)
          )}
        </SRow>
      </SCol>
    </SRow>
  );
};
