import React from 'react';
import { useTranslation } from 'react-i18next';
import { selectSearch } from 'core/utils/selectSearch';
import { CLIENT_INTERACTION_TYPES } from 'core/utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Select } from 'antd';
import styled from 'styled-components';
import Text from 'antd/lib/typography/Text';
import { updateUiWidgetFilters } from '../redux/ui/widgetSettingsModal/reducer';

const { Option } = Select;

const FilterContainer = styled.div`
  width: 100%;
  margin: 8px 0;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
`;

const WidgetCommunicationTypes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { filters } = useSelector(state => state.uiWidgetSettingsModal);

  return (
    <Col span={24}>
      <Text strong>
        {t('clientInteractionsPage.tableFilters.tableGeneralFilters.form.communicationsTypes')}
      </Text>
      <FilterContainer>
        <StyledSelect
          disabled={false}
          mode="multiple"
          allowClear
          optionLabelProp="label"
          placeholder={t(
            'clientInteractionsPage.tableFilters.tableGeneralFilters.form.communicationsTypes'
          )}
          value={filters?.communicationsTypes || []}
          maxTagCount={0}
          maxTagPlaceholder={selectedKeys =>
            `${t(
              'clientInteractionsPage.tableFilters.tableGeneralFilters.form.communicationsTypesShort'
            )}: ${selectedKeys.length}`
          }
          onChange={communicationsTypes => dispatch(updateUiWidgetFilters({ communicationsTypes }))}
          filterOption={(input, option) => selectSearch({ input, option, searchProp: 'children' })}
        >
          {Object.values(CLIENT_INTERACTION_TYPES).map(item => (
            <Option
              value={item.value}
              key={item.value}
              label={`${t(
                'clientInteractionsPage.tableFilters.tableGeneralFilters.form.communicationsTypes'
              )}: ${t(item.title)}`}
            >
              {t(item.title)}
            </Option>
          ))}
        </StyledSelect>
      </FilterContainer>
    </Col>
  );
};

export default WidgetCommunicationTypes;
