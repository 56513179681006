import { Col, Select } from 'antd';
import { selectSearch } from 'core/utils/selectSearch';
import { flatMap, head, intersectionWith, isEmpty, pickBy } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { actions } from 'redux/lists/clientInteractionsList';
import { getCustomFieldsByIds } from 'redux/selectors/customFields';
import { FilterContainer, StyledSelect } from './styled';

const { Option, OptGroup } = Select;

const getCustomFieldNameByKey = ({ customFieldsByIds, key }) =>
  head(Object.values(pickBy(customFieldsByIds, { key })))?.name;

const FunnelsFilters = ({
  disabled,
  updateFilters,
  filters,
  salePipelinesByIds = {},
  salePipelines = [],
  statusesByIds = {},
  customFieldsByIds = {},
  collapsedFilterGroups = []
}) => {
  const { t } = useTranslation();
  const isCollapsedFilterGroup = collapsedFilterGroups.includes('customFieldsFilters');

  const filterSalePipelines = pipelinesIds => {
    const allowedPipelinesStatusesIds = flatMap(
      salePipelines.filter(({ id }) => pipelinesIds.includes(id)),
      'statusesIds'
    );

    updateFilters({
      pipelinesIds,
      pipelineStatusesIds:
        isEmpty(filters.pipelineStatusesIds) || isEmpty(pipelinesIds)
          ? filters.pipelineStatusesIds
          : filters.pipelineStatusesIds.filter(statusId => {
              return allowedPipelinesStatusesIds.includes(statusId);
            })
    });
  };

  const selectedSalePipelines = intersectionWith(
    salePipelines,
    isEmpty(filters.pipelinesIds) ? Object.keys(salePipelinesByIds) : filters.pipelinesIds,
    ({ id }, filterId) => id === filterId
  );

  const salesPipelineStatusPlaceholder =
    getCustomFieldNameByKey({
      customFieldsByIds,
      key: 'sales_pipeline_status'
    }) || t('clientInteractionsPage.tableFilters.salesPipelines.salePipelineStatusesPlaceholder');

  const salesPipelinePlaceholder =
    getCustomFieldNameByKey({
      customFieldsByIds,
      key: 'sales_pipeline'
    }) || t('clientInteractionsPage.tableFilters.salesPipelines.salesPipelinesPlaceholder');

  const handleFilterOption = (inputValue, option) => {
    if (!option || !option.children || !option.label) return false;
    const searchValue = inputValue.toLowerCase();

    return (
      option.label.toLowerCase().includes(searchValue) ||
      option.children.toLowerCase().includes(searchValue)
    );
  };

  return (
    <>
      {isCollapsedFilterGroup && (
        <Col span={24}>
          {!isEmpty(salePipelines) && (
            <FilterContainer>
              <StyledSelect
                mode="multiple"
                disabled={disabled}
                showSearch
                allowClear
                optionLabelProp="label"
                maxTagCount={0}
                maxTagPlaceholder={selectedKeys =>
                  `${salesPipelinePlaceholder}: ${selectedKeys.length}`
                }
                placeholder={salesPipelinePlaceholder}
                value={filters.pipelinesIds || undefined}
                onChange={filterSalePipelines}
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'children' })
                }
              >
                {salePipelines.map(({ name, id }) => (
                  <Option value={id} key={id} label={name}>
                    {name}
                  </Option>
                ))}
              </StyledSelect>
            </FilterContainer>
          )}

          {!isEmpty(statusesByIds) && (
            <FilterContainer>
              <StyledSelect
                disabled={disabled}
                mode="multiple"
                style={{ width: '100%' }}
                optionLabelProp="label"
                allowClear
                placeholder={salesPipelineStatusPlaceholder}
                maxTagCount={0}
                maxTagPlaceholder={selectedKeys =>
                  `${salesPipelineStatusPlaceholder}: ${selectedKeys.length}`
                }
                onChange={pipelineStatusesIds => updateFilters({ pipelineStatusesIds })}
                value={filters.pipelineStatusesIds || []}
                filterOption={handleFilterOption}
              >
                {selectedSalePipelines.map(({ id, name, statusesIds = [] }) => (
                  <OptGroup key={id} label={name}>
                    {statusesIds.reduce((acc, id) => {
                      const status = statusesByIds[id];
                      return status
                        ? [
                            ...acc,
                            <Option value={status.id} key={status.id} label={status.name}>
                              {status.name}
                            </Option>
                          ]
                        : acc;
                    }, [])}
                  </OptGroup>
                ))}
              </StyledSelect>
            </FilterContainer>
          )}
        </Col>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const customFieldsByIds = getCustomFieldsByIds(state, true);
  const { byIds: salePipelinesByIds } = state.salePipelinesResource;
  const { byIds: statusesByIds } = state.salesPipelineStatusesResource;
  const { loading, filters } = state.clientInteractionsList;
  const { tableLoading, tableId, collapsedFilterGroups } = state.uiClientInteractions;

  return {
    disabled:
      tableLoading ||
      loading ||
      isEmpty(tableId) ||
      ownProps?.selectedWorkPlanTaskConfigurationId ||
      ownProps?.hasConflicts,
    salePipelinesByIds,
    salePipelines: Object.values(salePipelinesByIds),
    filters: ownProps?.hasConflicts ? {} : filters,
    customFieldsByIds,
    statusesByIds,
    collapsedFilterGroups
  };
};

const mapDispatchToProps = {
  updateFilters: actions.updateFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(FunnelsFilters);
