import React, { useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Input, Menu, message, Modal } from 'antd';
import { DownOutlined, LoadingOutlined, WechatOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import {
  clearThreadEndpoint,
  createThreadEndpoint,
  deleteThreadEndpoint,
  getApiKeyEndpoint,
  getThreadEndpoint,
  updateThreadEndpoint
} from 'core/api';
import { allowedGetSmmrzApiKey } from 'core/config';

// Статичный массив слов для автозаполнения
const autoCompleteOptions = [
  'Какое сейчас время?',
  'Сколько дней в году?',
  'Что такое искусственный интеллект?',
  'Дай оценку оператору',
  'Оценка коммуникация',
  'Опиши кратко о чем был звонок'
];

const CallToGPT = ({ call, reviewId }) => {
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const [visible, setVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [threadId, setThreadId] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [chatOpening, setChatOpening] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);

  // Реф для контейнера с сообщениями
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef?.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Автоскролл вниз при изменении массива сообщений
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleCloseModal = () => {
    setVisible(false);
    setCurrentMessage('');
    setThreadId(null);
    setApiKey(null);
    setChatOpening(false);
    setApiLoading(false);
  };

  const getApiKey = async () => {
    try {
      const response = await fetch(getApiKeyEndpoint, {
        method: 'POST',
        headers: {
          'X-Delay-Key': allowedGetSmmrzApiKey
        },
        body: JSON.stringify({
          amo_account_id: `qolio_${organizationId}`
        })
      });

      if (!response.ok) {
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }

      const data = await response.json();

      setApiKey(data?.api_key);
      setVisible(true);
      return data?.api_key;
    } catch (error) {
      console.log('error', error);
      message.error(
        'Не удалось получить доступ к чату. Пожалуйста, попробуйте еще раз или свяжитесь с нами.'
      );
      handleCloseModal();
    }
  };

  const getThread = async apiKey => {
    try {
      setApiLoading(true);
      const response = await fetch(
        `${getThreadEndpoint}?interaction_id=${call?.id}&review_id=${reviewId}`,
        {
          method: 'GET',
          headers: {
            'X-Delay-Key': apiKey
          }
        }
      );

      if (!response.ok) {
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }
      setApiLoading(false);
      return await response.json();
    } catch (error) {
      setApiLoading(true);
      console.log('error', error);
      message.error(
        'Произошла ошибка при получении данных чата. Пожалуйста попробуйте снова или свяжитесь с нами.'
      );
    }
  };

  const createThread = async apiKey => {
    try {
      const response = await fetch(`${createThreadEndpoint}`, {
        method: 'POST',
        headers: {
          'X-Delay-Key': apiKey
        },
        body: JSON.stringify({
          interaction_id: call?.id,
          review_id: reviewId
        })
      });

      if (!response.ok) {
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }

      const data = await response.json();
      return data?.data;
    } catch (error) {
      console.log('error', error);
      message.error('Не удалось создать чат. Пожалуйста, попробуйте еще раз или свяжитесь с нами.');
      handleCloseModal();
    }
  };

  const handleOpenChat = async () => {
    setChatOpening(true);
    const apiKey = await getApiKey();
    if (!apiKey) return;
    const thread = await getThread(apiKey);
    if (isEmpty(thread)) {
      const newTread = await createThread(apiKey);
      if (newTread) {
        setThreadId(newTread);
      }
    }
    if (!isEmpty(thread)) {
      setThreadId(thread?.meta?.thread_id);
      if (!isEmpty(thread?.data)) {
        setMessages(thread?.data);
      }
    }
  };

  const updateThread = async (text, newMessages) => {
    try {
      // Добавляем заглушку в виде спиннера до ответа GPT
      newMessages.push({
        role: 'assistant',
        message: null
      });
      setMessages(newMessages); // Обновляем чат
      const response = await fetch(`${updateThreadEndpoint}`, {
        method: 'POST',
        headers: {
          'X-Delay-Key': apiKey
        },
        body: JSON.stringify({
          id: threadId,
          messages: [
            {
              text
            }
          ]
        })
      });

      if (!response.ok) {
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      message.error('Что-то пошло не так. Пожалуйста попробуйте снова');
      console.log('error', error);
    }
  };

  const handleSendMessage = async (text = '') => {
    setApiLoading(true);
    const userMessage = text || currentMessage;
    if (typeof userMessage === 'string' && userMessage.trim()) {
      // Добавляем сообщение пользователя в чат
      const newMessages = [...messages, { role: 'user', message: userMessage }];
      const threadMsgs = await updateThread(userMessage, newMessages); // Отправляем написанное сообщение GPT
      setMessages(threadMsgs?.data || messages); // Обновляем чат
      setApiLoading(false);
      if (isEmpty(threadMsgs)) return;
      setCurrentMessage('');
    }
  };

  // Обработчик для установки текста из выпадающего списка
  const handleAutoFill = text => {
    if (apiLoading) return;
    setCurrentMessage(text); // Устанавливаем текст в поле ввода
    setTimeout(() => {
      handleSendMessage(text); // Отправляем сообщение
    }, 0);
  };

  const handleClearThread = async () => {
    try {
      setApiLoading(true);
      const response = await fetch(`${clearThreadEndpoint}?id=${threadId}`, {
        method: 'DELETE',
        headers: {
          'X-Delay-Key': apiKey
        }
      });

      if (!response.ok) {
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }
      setMessages([]);
      setApiLoading(false);
      message.success('Чат очищен');
    } catch (error) {
      setApiLoading(false);
      message.error('Не удалось очистить чат, пожалуйста попробуйте повторить попытку');
      console.log('error', error);
    }
  };

  const handleDeleteThread = async () => {
    try {
      setApiLoading(true);
      const response = await fetch(`${deleteThreadEndpoint}?id=${threadId}`, {
        method: 'DELETE',
        headers: {
          'X-Delay-Key': apiKey
        }
      });

      if (!response.ok) {
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }
      setMessages([]);
      handleCloseModal();
      message.success('Чат удалён');
    } catch (error) {
      setApiLoading(false);
      message.error('Не удалось удалить чат, пожалуйста попробуйте повторить попытку');
      console.log('error', error);
    }
  };

  // Создание меню для выпадающего списка
  const dropdownMenu = (
    <Menu>
      {autoCompleteOptions.map((option, index) => (
        <Menu.Item key={index} onClick={() => handleAutoFill(option)}>
          {option}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      {visible && (
        <Modal
          title="Обращение к GPT"
          centered
          maskClosable
          visible={visible}
          footer={null}
          width={800}
          onCancel={handleCloseModal}
        >
          {/* Отображение сообщений */}
          <div
            style={{
              maxHeight: '500px',
              overflowY: 'auto',
              marginBottom: '1rem',
              border: '1px solid #f0f0f0',
              borderRadius: '4px',
              padding: '10px',
              backgroundColor: '#fafafa'
            }}
          >
            {isEmpty(messages) && (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <WechatOutlined style={{ color: '#1877F2', fontSize: '200px' }} />
                <div style={{ fontSize: '22px' }}>Начните новый чат</div>
              </div>
            )}
            {!isEmpty(messages) &&
              messages.map(item => {
                const role = item?.role;
                const createdAt = item?.created_at;
                const message = item?.message;
                return (
                  <div
                    key={createdAt}
                    style={{
                      display: 'flex',
                      justifyContent: role === 'user' ? 'flex-end' : 'flex-start',
                      marginBottom: '8px'
                    }}
                  >
                    <div
                      style={{
                        maxWidth: apiLoading ? '100vh' : '75vh',
                        padding: '8px 12px',
                        borderRadius: '12px',
                        background: role === 'user' ? '#e6f7ff' : '#f5f5f5',
                        border: role === 'user' ? '1px solid #91d5ff' : '1px solid #d9d9d9'
                      }}
                    >
                      {message === null ? <LoadingOutlined /> : <>{message}</>}
                    </div>
                  </div>
                );
              })}
            <div ref={messagesEndRef} />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                marginTop: '20px',
                justifyContent: 'center',
                flexWrap: 'wrap'
              }}
            >
              {autoCompleteOptions.map((option, index) => (
                <div
                  style={{
                    padding: '6px',
                    backgroundColor: '#1877F2',
                    color: '#fff',
                    borderRadius: '6px',
                    cursor: 'pointer'
                  }}
                  key={index}
                  onClick={() => handleAutoFill(option)}
                >
                  {option}
                </div>
              ))}
            </div>
          </div>
          {/* Выпадающий список (Ещё) */}
          {/*<div*/}
          {/*  style={{*/}
          {/*    display: 'flex',*/}
          {/*    justifyContent: 'flex-end',*/}
          {/*    marginBottom: '1rem'*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Dropdown overlay={dropdownMenu} trigger={['click']} placement="bottomRight">*/}
          {/*    <div*/}
          {/*      style={{*/}
          {/*        display: 'flex',*/}
          {/*        alignItems: 'center',*/}
          {/*        justifyContent: 'center',*/}
          {/*        cursor: 'pointer'*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <DownOutlined />*/}
          {/*      <div style={{ marginLeft: '10px' }}>Ещё</div>*/}
          {/*    </div>*/}
          {/*  </Dropdown>*/}
          {/*</div>*/}
          {/* Поле ввода сообщения и кнопка отправки */}
          <Input.TextArea
            value={currentMessage}
            onChange={e => setCurrentMessage(e.target.value)}
            placeholder="Введите сообщение"
            rows={2}
            style={{ marginBottom: '8px' }}
          />
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>
              <Button
                type="primary"
                disabled={!currentMessage.trim() || apiLoading}
                onClick={() => handleSendMessage()}
                loading={apiLoading}
              >
                Отправить
              </Button>
              <Button
                type="default"
                loading={apiLoading}
                onClick={() => handleClearThread()}
                style={{ marginLeft: '10px' }}
              >
                Очистить чат
              </Button>
            </div>
            <div>
              <Button type="danger" loading={apiLoading} onClick={() => handleDeleteThread()}>
                Удалить чат
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <Button type="primary" onClick={handleOpenChat} loading={chatOpening}>
        Открыть чат с GPT
      </Button>
    </>
  );
};

export default CallToGPT;
