export default {
  templates: {
    templates: 'Шаблоны',
    addTemplate: 'Agregar plantilla',
    loadingTemplates: 'Cargando plantillas... 😤',
    empty: 'Sin plantillas',
    confirmDelete: '¿Eliminar plantilla?',
    searchTemplate: 'Buscar plantilla'
  }
};
