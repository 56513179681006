import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { selectSearch } from 'core/utils/selectSearch';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Select } from 'antd';
import styled from 'styled-components';
import Text from 'antd/lib/typography/Text';
import { updateUiWidgetFilters } from 'redux/ui/widgetSettingsModal/reducer';
import { taskDefinitionsResource } from 'redux/resources/taskDefinitions';
import { isEmpty } from 'lodash';
import CustomTag from './Tags/CustomTag';

const { Option } = Select;

const FilterContainer = styled.div`
  width: 100%;
  margin: 8px 0;
`;

const WidgetTasksDefinitions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { filters } = useSelector(state => state.uiWidgetSettingsModal);
  const loadingTaskDefinitions = useSelector(state => state.taskDefinitionsResource.loading);
  const taskDefinitions = useSelector(state => Object.values(state.taskDefinitionsResource.byIds));

  useEffect(() => {
    if (isEmpty(taskDefinitions)) {
      dispatch(taskDefinitionsResource.operations.load({ pagination: 'false' }));
    }
  }, []);

  return (
    <Col span={24}>
      <Text strong>
        Теги
      </Text>
      <FilterContainer>
        <Select
          placeholder={t(
            'clientInteractionsPage.tableFilters.tableReviewsFilters.form.tasksDefinitions'
          )}
          mode="multiple"
          style={{ width: '100%' }}
          allowClear
          maxTagCount={0}
          maxTagPlaceholder={selectedKeys => `Теги: ${selectedKeys.length}`}
          onChange={taskDefinitionsIds => dispatch(updateUiWidgetFilters({ taskDefinitionsIds }))}
          loading={loadingTaskDefinitions}
          disabled={filters?.reviewStatus !== 'true' || loadingTaskDefinitions}
          value={filters.taskDefinitionsIds || []}
          optionLabelProp="label"
          filterOption={(input, option) => selectSearch({ input, option, searchProp: 'label' })}
        >
          {taskDefinitions.map(({ id, labelName, labelColor }) => (
            <Option key={id} value={id} label={labelName}>
              <CustomTag color={labelColor} text={labelName} />
            </Option>
          ))}
        </Select>
      </FilterContainer>
    </Col>
  );
};

export default WidgetTasksDefinitions;
