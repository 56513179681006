import { destroy, get, post, put } from 'redux-bees';

const clientInteractionsEndpoints = apiPath => ({
  getClientInteractions: { method: get, path: `${apiPath}/client_interactions` },
  getClientInteractionsMeta: { method: get, path: `${apiPath}/client_interactions/meta` },
  getClientInteraction: { method: get, path: `${apiPath}/client_interactions/:id` },
  deleteClientInteraction: { method: destroy, path: `/reviews/:id` },
  updateClientInteraction: { method: put, path: `${apiPath}/client_interactions/:id` },
  updateClientInteractionOperator: {
    method: put,
    path: `${apiPath}/client_interactions/:id/update_client_interaction_operator`
  },
  updateClientInteractionCustomFields: {
    method: post,
    path: `/api/v3/change_custom_field`
  }
});

export default clientInteractionsEndpoints;
