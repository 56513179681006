import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Select } from 'antd';
import DurationPicker from 'components/Inputs/DurationPicker';
import FilterName from 'pages/ClientInteractionsPage/components/FilterName';
import RangeInputs from 'components/Inputs/RangeInputs';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import {
  CLIENT_INTERACTION_DIRECTIONS,
  CLIENT_INTERACTION_TYPES,
  DATE_PICKER_MODES,
  PERMISSIONS
} from 'core/utils/constants';
import { selectSearch } from 'core/utils/selectSearch';
import { get, isEmpty, isNil, sortBy } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import Icon from 'components/Icon';
import { actions } from 'redux/lists/clientInteractionsList';
import { getUnitsWithActiveUsers, getUsersByUnitIdsWithPermissions } from 'redux/selectors/users';
import { toogleFilterGroup } from 'redux/ui/clientInteractions/reducer';
import SpecificUsersSelect from 'components/Inputs/SpecificUsersSelect';
import ChoiceSelect from 'components/Inputs/ChoiceSelect';
import CustomDatePicker from 'components/DatePicker/DatePicker';
import decamelizeKeys from 'decamelize-keys';
import { integrationsResource } from 'redux/resources/integrations';
import { CategoryName, CategoryNameBlock, FilterContainer, StyledSelect } from './styled';
import { reviewFilters, tasksFilters } from './tableFiltersKeys';

const { Option, OptGroup } = Select;

const TableGeneralFilters = ({
  filters,
  disabled,
  loadingUsers,
  loadingUnits,
  loadingStatuses,
  loadingIntegrations,
  units = [],
  operators = [],
  roles = [],
  integrations = [],
  statusesByIds = {},
  levelsByIds = {},
  updateFilters,
  toogleFilterGroup,
  collapsedFilterGroups = [],
  loadIntegrations
}) => {
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const DirectionFilter = withConditionalRender(
    { hide: !get(filters, 'communicationsTypes', []).includes('phone_call') },
    FilterContainer
  );
  const { t } = useTranslation();
  const isCollapsedFilterGroup = collapsedFilterGroups.includes('tableGeneralFilters');
  const activeOperators = Object.values(operators).filter(o => o.active);
  const activeOperatorsWithLevels =
    filters?.userLevelsIds && !isEmpty(filters?.userLevelsIds)
      ? activeOperators.filter(o => filters?.userLevelsIds?.includes(o.levelId))
      : activeOperators;

  const RespondTimeFilter = withConditionalRender(
    {
      hide:
        !get(filters, 'communicationsTypes', []).includes('chat') &&
        !get(filters, 'communicationsTypes', []).includes('ticket')
    },
    FilterContainer
  );

  const DurationFilter = withConditionalRender(
    {
      hide:
        !get(filters, 'communicationsTypes', []).includes('chat') &&
        !get(filters, 'communicationsTypes', []).includes('phone_call')
    },
    FilterContainer
  );

  const NPSFilter = withConditionalRender(
    {
      hide:
        !get(filters, 'communicationsTypes', []).includes('chat') &&
        !get(filters, 'communicationsTypes', []).includes('ticket')
    },
    FilterContainer
  );

  const changeIsReviewedFilter = value => {
    const isReviewed = value === 'all' ? undefined : value;
    if (isReviewed !== 'true') {
      const emptyFilters = [...reviewFilters, ...tasksFilters].reduce(
        (acc, key) => ({ ...acc, [key]: undefined }),
        {}
      );

      return updateFilters({ ...emptyFilters, isReviewed });
    }

    updateFilters({ isReviewed });
  };

  const handleLoadIntegrations = () => {
    if (isEmpty(integrations)) {
      try {
        loadIntegrations(decamelizeKeys({ id: organizationId }));
      } catch (err) {
        console.log('error', err);
      }
    }
  };

  return (
    <>
      <Col span={24}>
        <CategoryNameBlock
          type="flex"
          align="middle"
          justify="space-between"
          onClick={() => toogleFilterGroup('tableGeneralFilters')}
        >
          <Col span={22}>
            <CategoryName>
              {t('clientInteractionsPage.tableFilters.tableGeneralFilters.title')}
            </CategoryName>
          </Col>
          <Col span={2}>
            {isCollapsedFilterGroup ? <Icon icon={DownOutlined} /> : <Icon icon={RightOutlined} />}
          </Col>
        </CategoryNameBlock>
      </Col>
      {isCollapsedFilterGroup && (
        <Col span={24}>
          <FilterContainer>
            <StyledSelect
              disabled={disabled}
              mode="multiple"
              allowClear
              optionLabelProp="label"
              placeholder={t(
                'clientInteractionsPage.tableFilters.tableGeneralFilters.form.communicationsTypes'
              )}
              value={filters?.communicationsTypes || []}
              maxTagCount={0}
              maxTagPlaceholder={selectedKeys =>
                `${t(
                  'clientInteractionsPage.tableFilters.tableGeneralFilters.form.communicationsTypesShort'
                )}: ${selectedKeys.length}`
              }
              onChange={communicationsTypes => updateFilters({ communicationsTypes })}
              filterOption={(input, option) =>
                selectSearch({ input, option, searchProp: 'children' })
              }
            >
              {Object.values(CLIENT_INTERACTION_TYPES).map(item => (
                <Option
                  value={item.value}
                  key={item.value}
                  label={`${t(
                    'clientInteractionsPage.tableFilters.tableGeneralFilters.form.communicationsTypes'
                  )}: ${t(item.title)}`}
                >
                  {t(item.title)}
                </Option>
              ))}
            </StyledSelect>
          </FilterContainer>

          {/* integrations list */}
          <FilterContainer>
            <ChoiceSelect
              disabled={disabled}
              mode="multiple"
              style={{ width: '100%' }}
              optionLabelProp="label"
              allowClear
              placeholder={t(
                'clientInteractionsPage.tableFilters.tableGeneralFilters.form.integrations'
              )}
              maxTagCount={0}
              maxTagPlaceholder={selectedKeys =>
                `${t(
                  'clientInteractionsPage.tableFilters.tableGeneralFilters.form.integrations'
                )}: ${selectedKeys.length}`
              }
              loading={loadingIntegrations}
              onChange={integrations => updateFilters({ integrations })}
              onClick={handleLoadIntegrations} // При клике загружаем список интеграций
              items={Object.values(integrations)}
              keyName="integrations"
              filters={filters?.integrations}
              updateFilters={updateFilters}
              value={filters.integrations || []}
              filterOption={(input, option) =>
                selectSearch({ input, option, searchProp: 'children' })
              }
            >
              {Object.values(integrations).map(({ id, name }) => (
                <Option key={id} value={id} label={name}>
                  {name}
                </Option>
              ))}
            </ChoiceSelect>
          </FilterContainer>

          {/* communication direction */}
          <DirectionFilter>
            <StyledSelect
              disabled={disabled}
              placeholder={t(
                'clientInteractionsPage.tableFilters.tableGeneralFilters.form.directionPlaceholder'
              )}
              value={filters.direction || 'all'}
              onChange={direction =>
                updateFilters({ direction: direction === 'all' ? undefined : direction })
              }
              optionLabelProp="label"
            >
              {Object.values(CLIENT_INTERACTION_DIRECTIONS).map(item => (
                <Option
                  value={item.value}
                  key={item.value}
                  label={`${t(
                    'clientInteractionsPage.tableFilters.tableGeneralFilters.form.direction'
                  )} ${t(item.title)}`}
                >
                  {t(item.title)}
                </Option>
              ))}
            </StyledSelect>
          </DirectionFilter>

          {/* communication date range */}
          <FilterContainer>
            <CustomDatePicker
              onChange={filters =>
                updateFilters({
                  clientInteractionSymbolicTimeRange: filters.clientInteractionSymbolicTimeRange,
                  clientInteractionTimeFrom: filters.clientInteractionTimeFrom,
                  clientInteractionTimeTo: filters.clientInteractionTimeTo
                })
              }
              value={{
                symbolicTimeRange: filters.clientInteractionSymbolicTimeRange,
                timeFrom: filters.clientInteractionTimeFrom,
                timeTo: filters.clientInteractionTimeTo
              }}
              mode={DATE_PICKER_MODES.clientInteraction}
              disabled={disabled}
              isClientInteractionPage
              loading={loadingStatuses || loadingUnits || loadingUsers}
              // page="client-interactions"
            />
          </FilterContainer>

          {/* respond time */}
          {/* <RespondTimeFilter>
        <FilterName>{t('clientInteractionsPage.tableFilters.tableGeneralFilters.form.responseTime')}</FilterName>
        <RangeInputs />
      </RespondTimeFilter> */}

          {/* number of messages */}
          <RespondTimeFilter>
            <FilterName>
              {t(
                'clientInteractionsPage.tableFilters.tableGeneralFilters.form.communicationPartsCount'
              )}
            </FilterName>
            <RangeInputs
              disabled={disabled}
              min={0}
              precision={0}
              value={{
                from: filters.communicationPartsCountFrom,
                to: filters.communicationPartsCountTo
              }}
              onChange={({ from, to }) =>
                updateFilters({
                  communicationPartsCountFrom: isNil(from) ? undefined : `${from}`,
                  communicationPartsCountTo: isNil(to) ? undefined : `${to}`
                })
              }
            />
          </RespondTimeFilter>

          {/* duration */}

          <DurationFilter>
            <FilterName>
              {t('clientInteractionsPage.tableFilters.tableGeneralFilters.form.duration')}
            </FilterName>
            <DurationPicker
              from={filters.durationFrom}
              to={filters.durationTo}
              disabled={disabled}
              allowClear
              onChange={({ from, to }) =>
                updateFilters({
                  durationFrom: isNil(from) ? undefined : `${from}`,
                  durationTo: isNil(to) ? undefined : `${to}`
                })
              }
            />
          </DurationFilter>

          {/* units */}
          <FilterContainer>
            <ChoiceSelect
              disabled={disabled || loadingUnits}
              mode="multiple"
              style={{ width: '100%' }}
              optionLabelProp="label"
              allowClear
              placeholder={t('clientInteractionsPage.tableFilters.tableGeneralFilters.form.unit')}
              maxTagCount={0}
              maxTagPlaceholder={selectedKeys =>
                `${t('clientInteractionsPage.tableFilters.tableGeneralFilters.form.units')} ${
                  selectedKeys.length
                }`
              }
              loading={loadingUnits}
              onChange={unitsIds => updateFilters({ unitsIds })}
              items={units}
              keyName="unitsIds"
              filters={filters?.unitsIds}
              updateFilters={updateFilters}
              value={filters.unitsIds || []}
              filterOption={(input, option) =>
                selectSearch({ input, option, searchProp: 'children' })
              }
            >
              {units.map(({ id, name }) => (
                <Option key={id} value={id} label={name}>
                  {name}
                </Option>
              ))}
            </ChoiceSelect>
          </FilterContainer>

          {/* users based on selected units */}
          <FilterContainer>
            <SpecificUsersSelect
              placeholder={t(
                'clientInteractionsPage.tableFilters.tableGeneralFilters.form.operators'
              )}
              mode="multiple"
              style={{ width: '100%' }}
              allowClear
              maxTagCount={0}
              maxTagPlaceholder={selectedKeys =>
                `${t('clientInteractionsPage.tableFilters.tableGeneralFilters.form.operators')}: ${
                  selectedKeys.length
                }`
              }
              onChange={operatorsIds => updateFilters({ operatorsIds })}
              updateFilters={updateFilters}
              keyName="operatorsIds"
              items={activeOperatorsWithLevels}
              loading={loadingUsers}
              disabled={disabled || loadingUsers}
              value={filters.operatorsIds || []}
              optionLabelProp="label"
              filterOption={(input, option) => selectSearch({ input, option, searchProp: 'label' })}
              usersToSelect={activeOperatorsWithLevels}
            />
          </FilterContainer>

          <FilterContainer>
            <StyledSelect
              disabled={disabled || loadingUnits}
              mode="multiple"
              style={{ width: '100%' }}
              optionLabelProp="label"
              allowClear
              placeholder={t('clientInteractionsPage.tableFilters.tableGeneralFilters.form.levels')}
              maxTagCount={0}
              maxTagPlaceholder={selectedKeys =>
                `${t('clientInteractionsPage.tableFilters.tableGeneralFilters.form.levels')}: ${
                  selectedKeys.length
                }`
              }
              loading={loadingUnits}
              onChange={userLevelsIds => updateFilters({ userLevelsIds })}
              value={filters.userLevelsIds || []}
              filterOption={(input, option) => selectSearch({ input, option, searchProp: 'label' })}
            >
              {roles.map(({ id, name, levelsIds = [] }) => (
                <OptGroup key={id} label={name}>
                  {sortBy(
                    levelsIds.reduce((acc, id) => {
                      const level = levelsByIds[id];
                      return level ? [...acc, level] : acc;
                    }, []),
                    'rank'
                  ).map(({ id, name }) => (
                    <Option value={id} key={id} label={name}>
                      {name}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </StyledSelect>
          </FilterContainer>

          {/* client interaction status */}
          <FilterContainer>
            <StyledSelect
              disabled={disabled || loadingStatuses}
              mode="multiple"
              style={{ width: '100%' }}
              optionLabelProp="label"
              allowClear
              placeholder={t('clientInteractionsPage.tableFilters.tableGeneralFilters.form.status')}
              maxTagCount={0}
              maxTagPlaceholder={selectedKeys =>
                `${t('clientInteractionsPage.tableFilters.tableGeneralFilters.form.status')}: ${
                  selectedKeys.length
                }`
              }
              loading={loadingStatuses}
              onChange={statusesIds => updateFilters({ statusesIds })}
              value={filters.statusesIds || []}
              filterOption={(input, option) =>
                selectSearch({ input, option, searchProp: 'children' })
              }
            >
              {Object.values(statusesByIds).map(({ id, name }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </StyledSelect>
          </FilterContainer>

          {/* NPS */}
          <NPSFilter>
            <FilterName>NPS</FilterName>
            <RangeInputs
              disabled={disabled}
              min={0}
              value={{ from: filters.npsFrom, to: filters.npsTo }}
              onChange={({ from, to }) =>
                updateFilters({
                  npsFrom: isNil(from) ? undefined : `${from}`,
                  npsTo: isNil(to) ? undefined : `${to}`
                })
              }
            />
          </NPSFilter>

          {/* hasReviews */}
          <FilterContainer>
            <StyledSelect
              placeholder={t(
                'clientInteractionsPage.tableFilters.tableGeneralFilters.form.reviewStatus'
              )}
              disabled={disabled}
              mode="default"
              style={{ width: '100%' }}
              onChange={changeIsReviewedFilter}
              value={filters.isReviewed || 'all'}
              optionLabelProp="label"
            >
              <Option
                key="all"
                value="all"
                label={`${t(
                  'clientInteractionsPage.tableFilters.tableGeneralFilters.form.reviewStatus'
                )}: ${t('constants.reviewStatuses.all')}`}
              >
                {t('constants.reviewStatuses.all')}
              </Option>
              <Option
                key="reviewed"
                value="true"
                label={`${t(
                  'clientInteractionsPage.tableFilters.tableGeneralFilters.form.reviewStatus'
                )}: ${t('constants.reviewStatuses.reviewed')}`}
              >
                {t('constants.reviewStatuses.reviewed')}
              </Option>
              <Option
                key="notReviewed"
                value="false"
                label={`${t(
                  'clientInteractionsPage.tableFilters.tableGeneralFilters.form.reviewStatus'
                )}: ${t('constants.reviewStatuses.notReviewed')}`}
              >
                {t('constants.reviewStatuses.notReviewed')}
              </Option>
            </StyledSelect>
          </FilterContainer>
        </Col>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { filters } = state.clientInteractionsList;
  const { tableLoading, tableId, collapsedFilterGroups } = state.uiClientInteractions;
  const loadingUnits = state.unitsResource.loading;
  const loadingUsers = state.usersResource.loading;
  const loadingStatuses = state.statusesResource.loading;
  const levelsByIds = state.levelsResource.byIds;
  const loadingIntegrations = state.integrationsResource.loading;
  const roles = sortBy(
    Object.values(state.rolesResource.byIds).filter(({ permissions = [] }) =>
      permissions.includes(PERMISSIONS.CAN_PERFORM_CLIENT_INTERACTION)
    ),
    'name'
  );

  if (loadingUnits || loadingUsers) return { filters, loadingUnits: true, loadingUsers };

  const operators = getUsersByUnitIdsWithPermissions(state, {
    permissions: [PERMISSIONS.CAN_PERFORM_CLIENT_INTERACTION],
    unitIds: isEmpty(filters.unitsIds) ? undefined : filters.unitsIds
  });

  const unitsWithActiveUsers = getUnitsWithActiveUsers(state);

  const integrations = state.integrationsResource.byIds;
  return {
    loadingUnits,
    loadingUsers,
    loadingStatuses,
    loadingIntegrations,
    roles,
    units: unitsWithActiveUsers,
    disabled:
      tableLoading ||
      isEmpty(tableId) ||
      ownProps.selectedWorkPlanTaskConfigurationId ||
      ownProps?.hasConflicts,
    operators,
    statusesByIds: state.statusesResource.byIds,
    levelsByIds,
    filters: ownProps?.hasConflicts ? {} : filters,
    collapsedFilterGroups,
    integrations
  };
};

const mapDispatchToProps = {
  updateFilters: actions.updateFilters,
  toogleFilterGroup,
  loadIntegrations: integrationsResource.operations.load
};

export default connect(mapStateToProps, mapDispatchToProps)(TableGeneralFilters);
