import { Alert } from 'antd';
import { get, isEqual } from 'lodash';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { usersResource } from 'redux/resources/users';
import { getUserWithRoleAndUnitById } from 'redux/selectors/users';
import SRow from 'components/Standard/SRow';
import { loadOrganization } from 'redux/entities/organization/operations';
import { PERMISSIONS } from 'core/utils/constants';
import UserAccountSettings from './Components/User/UserAccountSettings';
import OrganizationSettings from './Components/Organization/OrganizationSettings';
import OrganizationInfo from './Components/Organization/OrganizationInfo';

const loadUserById = usersResource.operations.loadById;

const UserSettingsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id: paramsId } = useParams();

  const currentUserId = useSelector(state =>
    get(state, 'reduxTokenAuth.currentUser.attributes.id')
  );

  const userId = paramsId || currentUserId;

  const user = useSelector(state => getUserWithRoleAndUnitById(state, userId), isEqual);

  const currentUserPermissions = get(user, 'role.permissions', []);

  const canManageOrgSettings = currentUserPermissions.includes(
    PERMISSIONS.CAN_MANAGE_ORGANIZATION_STRUCTURE
  );

  useEffect(() => {
    dispatch(loadUserById({ id: userId, include: 'unit,role' }));
    dispatch(loadOrganization());
  }, []);

  return (
    <SRow gutter={[16, 16]} padding="16px">
      <Helmet>
        <title>{t('pagesMeta.userSettingsPage.title')}</title>
      </Helmet>
      {userId !== currentUserId ? (
        <Alert
          message={`${t('userSettingsPage.messages.editingAnotherUsersSettingsPT1')} ${
            user?.name
          }, ${t('userSettingsPage.messages.editingAnotherUsersSettingsPT2')}`}
          type="warning"
          closable
          style={{ margin: 'auto', marginBottom: 20 }}
        />
      ) : null}

      <UserAccountSettings />

      {canManageOrgSettings && [
        <OrganizationInfo key="OrganizationInfo" />,
        <OrganizationSettings key="OrganizationSettings" />
      ]}
    </SRow>
  );
};

export default UserSettingsPage;
