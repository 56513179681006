import React from 'react';
import { Col, Tooltip } from 'antd';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { SRow, SText } from '../Standard';
import { isCustomFieldVisible } from '../../core/utils/isCustomFieldVisible';
import { Info } from '../Info';
import CustomFieldValueView from '../CustomFieldValueView';
import AiIcon from '../../assets/images/ai-icon.svg';

const CustomFieldsView = ({
  clientInteraction,
  communicationChainCustomFields,
  customFieldsByKeys
}) => {
  const { t } = useTranslation();
  return (
    <SRow>
      {Object.keys(communicationChainCustomFields).map(item => {
        const customFieldKey = item.replace('qolio_', '');
        if (!isCustomFieldVisible(customFieldsByKeys[customFieldKey])) {
          return '';
        }

        return (
          <Col key={customFieldKey} span={24}>
            {!Object.keys(customFieldsByKeys).includes(customFieldKey) && (
              <Info
                tooltip={{
                  title: t('components.communicationInfoModal.emptyFieldDescription')
                }}
                iconProps={{
                  style: { color: 'var(--red_6)', marginRight: 4 }
                }}
              />
            )}
            {get(customFieldsByKeys, `${customFieldKey}.usedForAi`, false) === true && (
              <img
                src={AiIcon}
                alt="ai-icon"
                style={{ marginRight: '5px', paddingBottom: '2px' }}
              />
            )}
            <SText strong>
              <Tooltip
                title={get(customFieldsByKeys, `${customFieldKey}.name`, `${customFieldKey}`)}
              >
                {get(customFieldsByKeys, `${customFieldKey}.name`, `${customFieldKey}`)}
              </Tooltip>
            </SText>
            {`: `}
            <CustomFieldValueView
              customFields={communicationChainCustomFields}
              customFieldsByKeys={customFieldsByKeys}
              customFieldKey={customFieldKey}
              sliced={false}
            />
          </Col>
        );
      })}
    </SRow>
  );
};

export default CustomFieldsView;
