import {
  camelCase,
  find,
  get,
  isEmpty,
  keyBy,
  omit,
  orderBy,
  reduce,
  snakeCase,
  uniqBy
} from 'lodash';
import moment from 'moment';
import { workPlanTaskConfigurationsResource } from 'redux/resources/workPlanTaskConfigurations';
import { getCustomFieldsByIds } from 'redux/selectors/customFields';
import { getScopedKey } from 'core/utils/addScopeToObject';
import decamilize from 'decamelize-keys-deep';
import { PERIOD_REPEAT } from 'core/utils/constants';
import camelcase from 'camelcase';
import { customFieldsResource } from 'redux/resources/customFields';

export const getDistributionByUserIds = ({
  distribution,
  reviewerBindingsByIds,
  removedReviewersIds
}) => {
  // * за такую разработку мне наверное прeмию дадут
  //  ? пример данных
  //  "reviewer_bindings": [
  // {
  //   "id": "id123",
  //   "user_id": "user_id",
  //   "chat_percentage_distribution": 0,
  //   "email_percentage_distribution": 0,
  //   "ticket_percentage_distribution": 0,
  //   "other_percentage_distribution": 0
  // }

  const clientInteractionTypes = Object.keys(distribution);
  const userIds = Object.keys(Object.values(distribution)[0].byIds);

  // * если проверяющий есть в removed но нету в users - Добавить его объект отдельно
  const removedDistributions = removedReviewersIds.reduce((acc, id) => {
    if (userIds.includes(id)) return acc;
    const data = {
      id: find(reviewerBindingsByIds, { reviewerId: id })?.id,
      position: userIds.length + 1,
      reviewerId: id,
      _destroy: '1'
    };
    return [...acc, data];
  }, []);

  const distributions = userIds.reduce((acc, userId, i) => {
    const userDistributions = clientInteractionTypes.reduce(
      (acc, type) => ({
        ...acc,
        [snakeCase(`${type}PercentageDistribution`)]: distribution[type].byIds[userId]
      }),
      {}
    );

    return [
      ...acc,
      {
        id: find(reviewerBindingsByIds, { reviewerId: userId })?.id,
        position: i,
        reviewerId: userId,
        _destroy: removedReviewersIds.includes(userId) ? '1' : '0',
        ...userDistributions
      }
    ];
  }, []);

  return [...distributions, ...removedDistributions];
};

const clientInteractionsTypesConfigsToFiltersAttributes = ({
  isNew,
  configFiltersIds,
  clientInteractionsTypesConfigs,
  communicationsDistributionMethod
}) => {
  const handleAttribute = (field, position) => {
    if (field.customFieldId) {
      return { ...omit(field, ['value', 'key', 'type']), position };
    }

    return omit(
      {
        ...field,
        key: snakeCase(field.key),
        settings: decamilize(field?.settings || {}),
        position
      },
      ['type', 'value']
    );
  };

  return Object.values(clientInteractionsTypesConfigs).map((config, position) => ({
    ...omit(
      config,
      isNew || !configFiltersIds.includes(config?.id)
        ? ['fields', 'id', 'type', 'fieldsIds', 'clientInteractionsMaxCount']
        : ['fields', 'type', 'fieldsIds', 'clientInteractionsMaxCount']
    ),
    position,
    [camelCase(communicationsDistributionMethod)]: config.clientInteractionsMaxCount,
    fieldsAttributes: config?.fields.map(handleAttribute)
  }));
};

export const mapUiDataForRequest = ({
  name,
  data = {},
  schedule,
  distribution,
  isNew = false,
  configFiltersIds,
  removedReviewersIds,
  reviewerBindingsByIds,
  clientInteractionsTypesConfigs,
  reviewersCommunicationsDistributionMethod
}) => {
  const communicationsDistributionMethod = schedule.distributionType;

  return {
    ...data,
    name: name.trim(),
    scheduleAttributes: {
      position: 1,
      id: schedule?.id || undefined,
      periodTimeFrom: schedule.periodTimeFrom.toISOString(),
      periodTimeTo: schedule.periodTimeTo.toISOString(),
      stopRepeatingAt: schedule?.stopRepeatingAt?.toISOString(),
      customRepeatEveryPeriod: schedule.customRepeatEveryPeriod || false,
      repeatEveryCount: schedule?.repeatEveryCount,
      repeatEvery:
        schedule?.customRepeatEveryPeriod && schedule?.repeatEvery === PERIOD_REPEAT.never.value
          ? PERIOD_REPEAT.day.value
          : schedule?.repeatEvery
    },
    communicationsDistributionMethod,
    reviewersCommunicationsDistributionMethod,
    reviewerBindingsAttributes: getDistributionByUserIds({
      distribution,
      reviewerBindingsByIds,
      removedReviewersIds
    }),
    filtersAttributes: clientInteractionsTypesConfigsToFiltersAttributes({
      clientInteractionsTypesConfigs,
      isNew,
      communicationsDistributionMethod,
      configFiltersIds
    })
  };
};

const scheduleResourceToUi = (
  { periodTimeFrom, periodTimeTo, stopRepeatingAt, ...resource },
  communicationsDistributionMethod
) => {
  return {
    ...resource,
    periodTimeFrom: moment(periodTimeFrom),
    periodTimeTo: moment(periodTimeTo),
    stopRepeatingAt: stopRepeatingAt ? moment(stopRepeatingAt) : undefined,
    distributionType: communicationsDistributionMethod
  };
};

const filtersResourcesToUi = (
  filtersResources,
  filerFieldsByIds,
  customFieldsByIds,
  communicationsDistributionMethod
) => {
  return keyBy(
    orderBy(
      filtersResources.map(resource => ({
        ...resource,
        clientInteractionsMaxCount: communicationsDistributionMethod
          ? resource[camelcase(communicationsDistributionMethod)]
          : 0,
        fields: resource.fieldsIds.map(id => {
          const field = filerFieldsByIds[id];
          const customField = get(customFieldsByIds, field?.customFieldId);

          return isEmpty(customField)
            ? {
                ...field,
                key: camelCase(field.key)
              }
            : {
                ...field,
                key: getScopedKey('customField', customField?.key)
              };
        })
      })),
      'position'
    ),
    'id'
  );
};

const reviewersBindingsResourcesToUi = (reviewersBindingsResources, clientInteactionsTypes) => {
  return reduce(
    clientInteactionsTypes,
    (acc, type) => {
      return {
        ...acc,
        [type]: {
          byIds: reduce(
            reviewersBindingsResources,
            (acc, resource) => ({
              ...acc,
              [resource.reviewerId]: resource[camelCase(`${type}PercentageDistribution`)]
            }),
            {}
          ),
          totalDistributed: reduce(
            reviewersBindingsResources,
            (acc, resource) => acc + resource[camelCase(`${type}PercentageDistribution`)],
            0
          )
        }
      };
    },
    {}
  );
};

export const loadConfigurationToUi = id => async (dispatch, getState) => {
  const state = getState();
  const configuration = await dispatch(
    workPlanTaskConfigurationsResource.operations.loadById({
      id,
      include: 'filters.fields,reviewer_bindings.reviewer,schedule'
    })
  );

  const organizationId = state.reduxTokenAuth.currentUser.attributes.user['organization-id'];

  await dispatch(customFieldsResource.operations.load({ organization_id: organizationId }));

  if (configuration) {
    const state = getState();
    const scheduleResource = state.workPlanTaskSchedulesResource.byIds[configuration?.scheduleId];
    const filtersResources = configuration.filtersIds.map(
      id => state.workPlanFiltersResource.byIds[id]
    );
    const reviewersBindingsResources = configuration.reviewerBindingsIds.map(
      id => state.workPlanReviewerBindingsResource.byIds[id]
    );
    const filerFieldsByIds = state.workPlanFilterFieldsResource.byIds;
    const customFieldsByIds = getCustomFieldsByIds(state);
    return {
      name: configuration.name,
      reviewersCommunicationsDistributionMethod:
        configuration.reviewersCommunicationsDistributionMethod,
      schedule: scheduleResourceToUi(
        scheduleResource,
        configuration.communicationsDistributionMethod
      ),
      reviewerBindingsIds: configuration.reviewerBindingsIds,
      clientInteractionsTypesConfigs: filtersResourcesToUi(
        filtersResources,
        filerFieldsByIds,
        customFieldsByIds,
        configuration.communicationsDistributionMethod
      ),
      distribution: reviewersBindingsResourcesToUi(
        reviewersBindingsResources,
        uniqBy(filtersResources.map(({ clientInteractionType }) => clientInteractionType))
      ),
      reviewersIds: reviewersBindingsResources.map(({ reviewerId }) => reviewerId)
    };
  }
  return {};
};
