import React from 'react';
import { Button, Space } from 'antd';
import { DeleteOutlined, PauseOutlined, ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const buttonStyle = {
  boxShadow: 'none',
  border: 'none',
  padding: '2px',
  background: '#F0F2F5'
};

const CheckedInfo = ({
  onDeleteTask,
  isRestart = false,
  restartAiTask,
  isProcessing = false,
  stopAiTask,
  record
}) => {
  const { t } = useTranslation();
  return (
    <Space style={{ background: '#F0F2F5', borderRadius: '4px', padding: '0 4px' }}>
      {isRestart ? (
        <ActionButton
          icon={<ReloadOutlined />}
          text={t('workPlanTaskConfigurationEditor.startTask')}
          onClick={restartAiTask}
        />
      ) : null}
      {isProcessing ? (
        <ActionButton
          icon={<PauseOutlined />}
          text={t('workPlanTaskConfigurationEditor.stopTask')}
          onClick={stopAiTask}
        />
      ) : null}
      <ActionButton
        icon={<DeleteOutlined style={{ color: 'red' }} />}
        text={t('general.delete')}
        onClick={onDeleteTask}
      />
    </Space>
  );
};

const ActionButton = ({ icon, text, onClick }) => (
  <Button icon={icon} style={buttonStyle} onClick={onClick}>
    {text}
  </Button>
);

export default CheckedInfo;
