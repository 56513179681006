function getMetaContent(name) {
  const contentTagWithName = document.getElementsByTagName('meta')[name];
  if (contentTagWithName != undefined) {
    const contentValue = contentTagWithName.getAttribute('content');
    if (contentValue != undefined) {
      return contentValue;
    }
  }
  return undefined;
}

const apiUrlFromMeta = getMetaContent('api-url');
const ymTokenFromMeta = getMetaContent('ym-token');
const ymRegTokenFromMeta = getMetaContent('ym-reg-token');
const fbPixelIdFromMeta = getMetaContent('fb-pixel-id');
const intercomAppIdFromMeta = getMetaContent('intercom-app-id');
const enableBoxVersionFromMeta = getMetaContent('enable-box-version');
const amoIntegrationIdFromMeta = getMetaContent('amo-integration-id');
const audioServiceOriginFromMeta = getMetaContent('audio-service-origin');
const freshchatWidgetTokenFromMeta = getMetaContent('freshchat-widget-token');
const naumenIntegrationKeyFromMeta = getMetaContent('naumen-integration-key');
const versionFromMeta = getMetaContent('version');
const isBoxFromMeta = getMetaContent('is-box');
const allowedGetSmmrzApiKeyKeysFromMeta = getMetaContent('allowed-get-smmrz-api-key-keys');

export const apiUrl =
  apiUrlFromMeta === '${BACKEND_URL}' ? process.env.REACT_APP_API_URL : apiUrlFromMeta;

export const ymToken =
  ymTokenFromMeta === '${YM_TOKEN}' ? process.env.REACT_APP_YM_TOKEN : ymTokenFromMeta;

export const ymRegToken =
  ymRegTokenFromMeta === '${YM_REG_TOKEN}'
    ? process.env.REACT_APP_YM_REG_TOKEN
    : ymRegTokenFromMeta;

export const fbPixelId =
  fbPixelIdFromMeta === '${FB_PIXEL_ID}' ? process.env.REACT_APP_FB_PIXEL_ID : fbPixelIdFromMeta;

export const intercomAppId =
  intercomAppIdFromMeta === '${INTERCOM_APP_ID}'
    ? process.env.REACT_APP_INTERCOM_APP_ID
    : intercomAppIdFromMeta;

export const isBoxVersion = !!(enableBoxVersionFromMeta === '${ENABLE_BOX_VERSION}'
  ? process.env.REACT_APP_ENABLE_BOX_VERSION
  : enableBoxVersionFromMeta);

export const amoIntegrationId =
  amoIntegrationIdFromMeta === '${AMO_INTEGRATION_ID}'
    ? process.env.REACT_APP_AMO_INTEGRATION_ID
    : amoIntegrationIdFromMeta;

export const naumenIntegrationKey =
  naumenIntegrationKeyFromMeta === '${NAUMEN_INTEGRATION_KEY}'
    ? process.env.REACT_APP_NAUMEN_INTEGRATION_KEY
    : naumenIntegrationKeyFromMeta;

export const audioServiceOrigin =
  audioServiceOriginFromMeta === '${AUDIO_SERVICE_ORIGIN}'
    ? process.env.REACT_APP_AUDIO_SERVICE_ORIGIN
    : audioServiceOriginFromMeta;

export const freshchatWidgetToken =
  freshchatWidgetTokenFromMeta === '${FRESHCHAT_WIDGET_TOKEN}'
    ? process.env.REACT_APP_FRESHCHAT_WIDGET_TOKEN
    : freshchatWidgetTokenFromMeta;

export const isBox = isBoxFromMeta?.toLowerCase() === 'true';

export const version =
  versionFromMeta === '${VERSION}' ? process.env.REACT_APP_VERSION : versionFromMeta;

export const allowedGetSmmrzApiKey =
  allowedGetSmmrzApiKeyKeysFromMeta === '${ALLOWED_GET_SMMRZ_API_KEY_KEYS}'
    ? process.env.REACT_APP_ALLOWED_GET_SMMRZ_API_KEY_KEYS
    : allowedGetSmmrzApiKeyKeysFromMeta;
