import { camelCase, reduce } from 'lodash';

export const getScopedKey = (scope, key) => {
  // ignore camelCase for custom fields. Store custom field as real name
  if (scope === 'customField') return `custom_field/${key}`;
  return camelCase(`${scope}_${key}`);
};

const addScopeToObjectKeys = (scope = '', object) => {
  if (typeof object === 'string') return;
  return reduce(object, (res, val, key) => ({ ...res, [getScopedKey(scope, key)]: val }), {});
};

export default addScopeToObjectKeys;
